import {Card, Headline, LargeTitle, Progress, Slider, Subheadline} from "@telegram-apps/telegram-ui";
import {formatNumber} from "../../helpers/format_helper";
import IconLottyCoin from "../icons/IconLottyCoin";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getBalance} from "../../slices/balance/thunk";
import {setIncreaseChanceCoins} from "../../slices/app/reducer";

const IncreaseChance = (props) => {


    // const {remainingBalance, setRemainingBalance} = props
    const [currentSliderValue, setCurrentSliderValue] = useState(0)
    const {balance} = useSelector(state => state.Balance)
    const {increaseChanceCoins, resetChanceSlider} = useSelector(state => state.App)
    const [chance, setChance] = useState(1)
    const [remainingBalance, setRemainingBalance] = useState(0)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getBalance())
    }, [])


    useEffect(() => {
        if (resetChanceSlider) {
            setRemainingBalance(0)
            setCurrentSliderValue(0)
            setChance(1)
            const copiedDiv = document.querySelector("div[copied] .lotty-balance");
            if (copiedDiv)
                copiedDiv.remove()
        }
    }, [resetChanceSlider])

    // useEffect(() => {
    //     if (increaseChanceCoins === 0){
    //
    //     }
    // }, [increaseChanceCoins])

    useEffect(() => {
        if (remainingBalance > 0)
            dispatch(setIncreaseChanceCoins(remainingBalance))
    }, [remainingBalance]);

    useEffect(() => {
        let new_chance;
        let remaining
        if (currentSliderValue > 0) {
            remaining = currentSliderValue
            new_chance = Number(1 + currentSliderValue * 0.0001 + Math.floor(currentSliderValue / 1000) * 0.0001).toFixed(1);
            setRemainingBalance(remaining)
            setChance(new_chance)
        }
    }, [currentSliderValue]);

    const handleSliderChange = (value) => {
        setCurrentSliderValue(value)
        copyToDiv()
        window.Telegram.WebApp.HapticFeedback.impactOccurred('soft')
    }

    const copyToDiv = () => {
        const sourceDiv = document.querySelector('.сopy-wrapper');
        const targetDiv = document.querySelector('.lotty-slider .tgui-4de4feff2dcc975c');
        const lottyDiv = document.querySelector('.lotty-balance');

        let targetWidth = targetDiv.getBoundingClientRect().width;

        if (targetWidth < 50) {
            lottyDiv.style.opacity = 0;
        } else if (targetWidth >= 50 && targetWidth < 60) {
            lottyDiv.style.opacity = 0.1;
        } else if (targetWidth >= 60 && targetWidth < 70) {
            lottyDiv.style.opacity = 0.2;
        } else if (targetWidth >= 70 && targetWidth < 80) {
            lottyDiv.style.opacity = 0.3;
        } else if (targetWidth >= 80 && targetWidth < 90) {
            lottyDiv.style.opacity = 0.4;
        } else if (targetWidth >= 90 && targetWidth < 100) {
            lottyDiv.style.opacity = 0.5;
        } else if (targetWidth >= 100 && targetWidth < 110) {
            lottyDiv.style.opacity = 0.6;
        } else if (targetWidth >= 110 && targetWidth < 120) {
            lottyDiv.style.opacity = 0.7;
        } else if (targetWidth >= 120 && targetWidth < 130) {
            lottyDiv.style.opacity = 0.8;
        } else if (targetWidth >= 130 && targetWidth < 140) {
            lottyDiv.style.opacity = 0.9;
        } else if (targetWidth >= 140) {
            lottyDiv.style.opacity = 1;
        }



        // Если оба div существуют
        if (sourceDiv && targetDiv) {
            targetDiv.innerHTML = sourceDiv.innerHTML;
            targetDiv.setAttribute('copied', 1)
        }
    }

    return (
        <Card className={"p-3 w-100"}>
            <div className={"d-flex flex-column w-100"}>
                <Headline className={"d-flex gap-1 align-items-center justify-content-center subtitle-color text-center"}>
                    Increase your chance with Lotty Coins
                </Headline>
                <div className={"d-flex gap-2 align-items-center justify-content-center position-relative ticket-btns py-3"}>
                    <LargeTitle
                        style={{
                            fontFamily: "Chakra Petch",
                            fontSize: 58,
                            opacity: balance?.coin_balance < 1000 ? 0.5 : 1,
                            lineHeight: "inherit!important",
                            color: '#52c2f6',
                            transition: '0.2s all'
                        }}
                        weight={3}>
                        <sub
                            style={{
                                top: 0,
                                fontSize: "0.5em"
                            }}
                        >x</sub>
                        {chance}
                    </LargeTitle>

                </div>
                <div className={"header-bg wrapper-slider position-relative"}>
                    <div className={"сopy-wrapper d-none"}>
                        <div className={"d-flex align-items-center justify-content-center lotty-balance"}>
                            <div className={"d-flex gap-1 align-items-center"}>
                                <Subheadline className={`d-flex gap-1 align-items-center`}>
                                    -{formatNumber(remainingBalance)}
                                </Subheadline>
                                <IconLottyCoin
                                    style={{
                                        height: 20,
                                        width: 20
                                    }}
                                />

                            </div>

                        </div>
                    </div>
                    {balance?.coin_balance < 1000 && <div className={"d-flex align-items-center justify-content-center not-enough"}>
                        <div className={"d-flex gap-1 align-items-center"}>
                            <Subheadline className={`d-flex gap-1 align-items-center`}>
                                Not enough Lotty Coins
                            </Subheadline>
                            <IconLottyCoin
                                style={{
                                    filter: 'grayscale(1)',
                                    height: 20,
                                    width: 20
                                }}
                            />

                        </div>

                    </div>}
                    <Slider
                        disabled={true}
                        className={"lotty-slider"}
                        onChange={(value) => handleSliderChange(value)}
                        value={currentSliderValue}
                        max={Number(balance?.coin_balance)}
                        min={0}
                    />
                </div>
            </div>
        </Card>
    )
}

export default IncreaseChance