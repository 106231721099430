import {Card, Subheadline, Avatar, Caption, Button, Skeleton} from "@telegram-apps/telegram-ui";
import IconLottyStar from "../../Components/icons/IconLottyStar";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getFriends} from "../../slices/friends/thunk";
import {formatNumber} from "../../helpers/format_helper";
import FriendSkeleton from "./FriendSkeleton";
import IconLottyCoin from "../../Components/icons/IconLottyCoin";
import {openTgUser, openUser} from "../../helpers/telegram_app_helper";
import {AnimatePresence, motion} from "framer-motion";

const FriendsList = () => {
    const dispatch = useDispatch()
    const {friends, meta, loading, showSkeleton, loaded} = useSelector(state => state.Friends)
    const {counters} = useSelector(state => state.App)

    useEffect(() => {
        dispatch(getFriends(1, false, true))
    }, [])


    const showMore = () => {
        dispatch(getFriends(meta.current_page + 1, true))
    }

    const getTransactionIcon = (type) => {
        switch(type) {
            case 'star' :
                return <IconLottyStar style={{height: 20, width: 20}}/>
            case 'coin' :
                return <IconLottyCoin style={{height: 20, width: 20}} />
            default:
                return ''
        }
    }

    return (
        <div className={"mt-2 d-block"}>

                <Subheadline className={"text-grey ms-3 d-inline-block"}>
                    <Skeleton visible={!loaded}>
                        Friends ({formatNumber(meta?.total)})
                    </Skeleton>
                </Subheadline>


            <Card className={"d-block w-100 mt-2 p-3"}>

                <div className={"d-flex flex-column gap-3"}>
                    {loaded && !friends?.length &&
                        <div className={"text-center"}>
                            <Subheadline>You haven’t invited any friends yet.</Subheadline>
                        </div>
                    }
                    {!loaded && Array.from({ length: 4 }, (_, index) => {
                        if (index < 4)
                            return <FriendSkeleton key={index} />
                        return ''
                    })}
                    <AnimatePresence>
                        {friends && friends.map((el, i) => (
                            <motion.div
                                key={el.id}
                                layout
                            >
                            <div
                                onClick={() => {
                                    openUser(el)
                                }}
                                role={"button"}
                                key={i} className={"task d-flex flex-row justify-content-between align-items-center"}>
                                <div className={"d-flex flex-row gap-2 align-items-center w-75"}>
                                    <div className={""}>
                                        <Avatar
                                            size={40}
                                            src={el?.username ? `https://unavatar.io/telegram/${el?.username}` : 'https://unavatar.io/telegram/empty'}
                                        />
                                    </div>
                                    <div className={"d-flex flex-column"}>
                                        <Subheadline className={"text-break"} weight={2}>
                                            {openTgUser(el)}
                                        </Subheadline>
                                        <Caption className={"subtitle-color"} weight={3}>
                                            {el.created_at}
                                        </Caption>
                                    </div>
                                </div>
                                <div className={"d-flex bonus-amount align-items-center gap-2"}>
                                <span>
                                    + {formatNumber(el?.transactions[0]?.amount)}
                                </span>
                                    {getTransactionIcon(el?.transactions[0].token)}
                                </div>
                            </div>
                            </motion.div>
                        ))}
                    </AnimatePresence>

                </div>
                {meta?.last_page > meta?.current_page &&
                    <div className={"pagination d-flex justify-content-center pt-2"}>
                        <Button
                            loading={loading}
                            onClick={showMore}
                            mode={"plain"}
                        >Show more
                        </Button>
                </div>}

            </Card>
        </div>

    )

}

export default FriendsList