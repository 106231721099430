import {Button, Caption, Card, FixedLayout, Headline, Subheadline, Title} from "@telegram-apps/telegram-ui";
import React, {useEffect, useState} from "react";
import IconLottyStar from "../../../icons/IconLottyStar";
import giftLottie from "../../../../assets/lottie/gift.json";
import Lottie from "lottie-react";
import PrizeTable from "../../../../pages/Home/PrizeTable";
import Drawer from "../../Drawer";
import {useDispatch, useSelector} from "react-redux";
import {formatNumber} from "../../../../helpers/format_helper";
import {checkTaskApi} from "../../../../helpers/real_api_helper";
import {toast} from "react-toastify";
import ModalSelectNumber from "./ModalSelectNumber";
import Prizes from "../Parts/Prizes";
import IconTelegramStar from "../../../icons/IconTelegramStar";
import chuvak from "../../../../assets/lottie/dotlottie/chuvak.lottie";
import free from "../../../../assets/lottie/dotlottie/fire.lottie";
import {DotLottieReact} from "@lottiefiles/dotlottie-react";
import ModalConfirm from "../../../../pages/Profile/ModalConfirm";
import {getBalance} from "../../../../slices/balance/thunk";
import {payWithTelegramStars} from "../../../../slices/app/thunk";
import ModalRaffleTasks from "./ModalRaffleTasks";
import OptimizedLottie from "../../OptimizedLottie";

const ModalRaffle = (props) => {
    const {user} = useSelector(state => state.Auth)
    const {balance} = useSelector(state => state.Balance)
    const {telegramStarsLoading} = useSelector(state => state.App)
    const {isOpen, modalClose, selectedRaffle, selectedTab} = props
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [loadingConfirm, setLoadingConfirm] = useState(false)
    const [showSelectNumber, setShowSelectNumber] = useState(false)
    const [modalConfirmOpen, setModalConfirmOpen] = useState(false);
    const [useSilverStars, setUseSilverStars] = useState(false)
    const [paymentType, setPaymentType] = useState('task')
    const [telegramInvoiceLink, setTelegramInvoiceLink] = useState(false)
    const [showModalRaffleTasks, setShowModalRaffleTasks] = useState(false)

    useEffect(() => {
        dispatch(getBalance())
    }, [dispatch])

    const closeRaffleTasks = () => {
        setShowModalRaffleTasks(false)
    }

    const checkCompletion =  () => {
        setLoading(true)
        checkTaskApi(selectedRaffle?.task)
            .then(async res => {
                setPaymentType('task')
                await closeRaffleTasks()
                await setShowSelectNumber(true)
            })
            .catch(err => {

                const formattedMessage = (
                    <ul>
                        {err.data.message.split("\n").map((el, i) => (
                            <li key={i}>{el}</li>
                        ))}
                    </ul>
                )
                toast.warning(formattedMessage)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const closeSelectNumber = () => {
        setUseSilverStars(false)
        setShowSelectNumber(false)
        setPaymentType('task')
        setTelegramInvoiceLink(false)
        modalClose()
    }

    const payWithSilverStars = () => {

        if (balance?.star_balance >= 2000) {
            setShowSelectNumber(true)
            setUseSilverStars(true)
            setPaymentType('silver_stars')
        }
        else {
            toast.warning('Insufficient funds')
        }
        setModalConfirmOpen(false)
    }

    const sendTelegramInvoice = (type) => {
        let payload = JSON.stringify({
            id: selectedRaffle.id,
            user_id: user.id
        })
        dispatch(payWithTelegramStars(type, payload))
    }

    const payWithYellowStars = () => {
        setShowSelectNumber(true)
        setPaymentType('telegram_stars')
    }

    return (
        <Drawer
           isOpen={isOpen}
           onClose={modalClose}
        >
            <ModalSelectNumber
                selectedRaffle ={selectedRaffle}
                isOpen={showSelectNumber}
                paymentType={paymentType}
                telegramInvoiceLink={telegramInvoiceLink}
                modalClose={closeSelectNumber}
            />
            <ModalConfirm
                isOpen={modalConfirmOpen}
                modalClose={() => setModalConfirmOpen(false)}
                onConfirm={() => payWithSilverStars()}
            />
            <ModalRaffleTasks
                loading={loading}
                isOpen={showModalRaffleTasks}
                modalClose={closeRaffleTasks}
                selectedRaffle={selectedRaffle}
                checkCompletion={checkCompletion}
            />
            <div
                className={"d-flex w-100 flex-column gap-3 justify-content-center align-items-center"}
                style={{
                    paddingBottom: selectedRaffle?.current_participants != selectedRaffle?.max_participants ? '170px' : ''
                }}
            >
                <div className={""}>
                    <Title>Lotty #{selectedRaffle?.id}</Title>
                </div>
                <div className={"task-icon"}>
                    <Lottie
                        style={{
                            width: 160,
                            height: 80
                        }}
                        animationData={giftLottie} loop={false}
                    />
                </div>
                <div className={"d-flex flex-row gap-2 align-items-center"}>
                    <Prizes prizes={selectedRaffle?.prizes} />
                </div>
                <div className={"d-flex flex-column text-center align-items-center "}>
                    <div className={"d-flex gap-1 flex-column align-items-center"}>
                        <Subheadline weight={2}>
                            Participants
                        </Subheadline>
                        <div className={"d-flex gap-1"}>
                            <OptimizedLottie
                                src={chuvak}
                                loop
                                autoplay
                                style={{
                                    height: 20,
                                    width: 20,
                                }}
                            />
                            <span
                                className={"ton_num"}
                            >
                         {formatNumber(selectedRaffle?.current_participants)} / {formatNumber(selectedRaffle?.max_participants)}
                        </span>
                        </div>
                    </div>
                    <Caption className={"subtitle-color mt-1"}>
                        The raffle will start once all participants have joined.
                    </Caption>
                </div>
                <Headline
                    className={"text-center"}
                    level="1"
                    weight="2"
                >
                   Winner table
                </Headline>
                <PrizeTable
                    matrix={selectedRaffle?.matrix}
                />
            </div>
            {selectedRaffle?.current_participants != selectedRaffle?.max_participants &&
                <FixedLayout
                    className={"p-3 safe-padding-bottom main-bg"}
                    vertical="bottom">
                    <div className={"d-flex flex-column gap-2 w-100 text-center"}>
                        <Button
                            mode={'white'}
                            before={
                                <OptimizedLottie
                                    src={free}
                                    loop
                                    autoplay
                                    style={{
                                        height: 20,
                                        width: 20,
                                    }}
                                />
                            }
                            loading={loading}
                            onClick={() => {
                                setShowModalRaffleTasks(true)
                            }}
                        >
                            Free Play
                        </Button>
                        <Caption className={"subtitle-color"} weight={1} style={{
                        }}>OR</Caption>
                        <div className={"d-flex gap-2"}>
                            <Button
                                mode={'gray'}
                                onClick={() => setModalConfirmOpen(true)}
                                before={
                                    <IconLottyStar
                                        style={{
                                            height: 15,
                                            width: 15
                                        }}
                                    />
                                }
                                className={"w-100"}
                            >
                                Pay 2000
                            </Button>
                            <Button
                                mode={'gray'}
                                style={{
                                    color: '#fdb345'
                                }}
                                onClick={() => payWithYellowStars()}
                                loading={telegramStarsLoading}
                                before={
                                    <IconTelegramStar
                                        style={{
                                            height: 20,
                                            width: 20
                                        }}
                                    />
                                }
                                className={"w-100"}
                            >
                                Pay 20
                            </Button>
                        </div>
                    </div>
                </FixedLayout>
            }
        </Drawer>
    )
}

export default ModalRaffle