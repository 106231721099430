import Countdown, {zeroPad} from "react-countdown";
import React from "react";

const MyCountdown = (props) => {
    const {date, onComplete} = props

    const Completionist = () => <span>You are good to go!</span>;
    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            onComplete()
            return <Completionist />;
        } else {
            // Render a countdown
            return (
                <div className={"d-flex gap-1 my-countdown"}>
                    <div className={"main-bg p-2"}>
                        {zeroPad(hours)}
                    </div>
                    <span>:</span>
                    <div className={"main-bg p-2"}>
                        {zeroPad(minutes)}
                    </div>
                    <span>:</span>
                    <div className={"main-bg p-2"}>
                        {zeroPad(seconds)}
                    </div>
                </div>

            );
        }
    };
    return (
        <Countdown
            zeroPadTime={2}
            date={date} renderer={renderer}
        />
    )
}

export default MyCountdown