import React, { createContext, useContext, useEffect, useRef, useMemo, useState } from 'react';
import { io } from 'socket.io-client';
import { useSelector } from 'react-redux';

const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
    const socketRef = useRef(null);
    const { user } = useSelector(state => state.Auth);
    const [isConnected, setIsConnected] = useState(false);

    useEffect(() => {
        if (!socketRef.current) {
            socketRef.current = io(process.env.REACT_APP_SOCKET_SERVER, {
                transports: ['websocket'],
                secure: true,
                rejectUnauthorized: false,
            });

            // Слушаем событие подключения
            socketRef.current.on('connect', () => {
                setIsConnected(true);
                console.log(`Connected with socket ID: ${socketRef.current.id}`);
                if (user) {
                    // Отправляем информацию о пользователе после подключения
                    socketRef.current.emit('register', user.id);
                    console.log(`User ${user.id} registered with socket ID: ${socketRef.current.id}`);
                }
            });

            socketRef.current.on('connect_error', (error) => {
                console.error('Socket connection error:', error);
            });
        }

        // Очистка при размонтировании
        return () => {
            if (socketRef.current) {
                socketRef.current.off('connect');
                socketRef.current.off('connect_error');
            }
        };
    }, [user]); // Перерегистрировать сокет при изменении пользователя

    const socketValue = useMemo(() => socketRef.current, [socketRef.current]);

    return (
        <SocketContext.Provider value={{ socket: socketValue, isConnected }}>
            {children}
        </SocketContext.Provider>
    );
};

export const useSocket = () => {
    const context = useContext(SocketContext);
    if (!context) {
        throw new Error('useSocket must be used within a SocketProvider');
    }
    return context;
};