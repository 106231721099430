import { Swiper, SwiperSlide } from 'swiper/react';
import {Autoplay, Navigation} from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import LotteryCard from "../../Components/custom/LotteryCard";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Caption, Subheadline} from "@telegram-apps/telegram-ui";
import {getTopRaffles} from "../../slices/raffles/thunk";
import FinishedLotteryCard from "../../Components/custom/FinishedLotteryCard";
import RaffleSkeleton from "../../Components/custom/Raffle/RaffleSkeleton";
import WinnerTable from "./WinnerTable";
import NewVerticalCard from "../../Components/custom/Raffle/NewVerticalCard";

const TopRaffles = () => {
    const {topRaffles, selectedTab, topRaffleSkeleton} = useSelector(state => state.Raffles)
    const dispatch = useDispatch()

    useEffect(() => {
        if (selectedTab === 'active')
            dispatch(getTopRaffles(1, selectedTab))
    }, [selectedTab])

    return (
        selectedTab == 'active' &&
        <div className={"d-block"}>
            <div className={"ps-4 pb-2"}>
                <Caption className={"subtitle-color"} weight={2}>{selectedTab == 'active' ? 'Top 10 Available Lotty' : ''} </Caption>
            </div>
            <Swiper
                slidesPerView={2}
                spaceBetween={8}
                autoplay={{
                    delay: 25000,
                    disableOnInteraction: true,
                }}
                modules={[Autoplay, Navigation]}
                className="mySwiper px-2"
            >
                {!topRaffleSkeleton && [1, 2].map((el, i) => (
                    <SwiperSlide
                        key={i}
                    >
                        <RaffleSkeleton/>
                    </SwiperSlide>
                ))}
                {topRaffleSkeleton && topRaffles && topRaffles.map((el, i) => (
                    <SwiperSlide
                        key={i}
                    >
                        <NewVerticalCard
                            current={el}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
}

TopRaffles.whyDidYouRender = true;
export default TopRaffles