import {createSlice} from "@reduxjs/toolkit";

export const initialState = {
    transactions: [],
    loaded: false,
    meta: null
}

const appSlice = createSlice({
    name: "transactions",
    initialState,
    reducers: {
       setTransactions(state, action) {
           if (action.payload.append) {
               state.transactions = [...state.transactions, ...action.payload.data];
           } else {
               state.transactions = action.payload.data;
           }
           state.meta = action.payload.meta
           state.loaded = true
       }
    }
})

export const {
    setTransactions,
} = appSlice.actions

export default appSlice.reducer