import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {Wheel} from 'react-custom-roulette'; // импортируй компонент Wheel, если нужно

const PrizeWheel = ({ onStopSpinning }) => {
    const { prizeNum, mustStartSpinning } = useSelector(state => state.Games);

    const data = useMemo(() => [
        {
            option: 3,
            style: {
                backgroundColor: '#6868f6',
                textColor: "white"
            },
            image: {
                uri: 'images/roulette/ton-3.svg',
                sizeMultiplier: 0.5,
                offsetX: -70,
                offsetY: -15,
                landscape: true
            }
        },
        {
            option: '1000',
            style: {
                backgroundColor: '#5ea6e4'
            },
            image: {
                uri: 'images/roulette/lty-1000.svg',
                sizeMultiplier: 0.5,
                offsetX: 0,
                offsetY: -15,
                landscape: true
            }
        },
        {
            option: '0.01',
            style: {
                backgroundColor: '#5ea6e4'
            },
            image: {
                uri: 'images/roulette/ton-0-01.svg',
                sizeMultiplier: 0.5,
                offsetX: 0,
                offsetY: -15,
                landscape: true
            }
        },
        {
            option: '500',
            style: {
                backgroundColor: '#5ea6e4'
            },
            image: {
                uri: 'images/roulette/lty-500.svg',
                sizeMultiplier: 0.5,
                offsetX: 0,
                offsetY: -15,
                landscape: true
            }
        },
        {
            option: '50',
            style: {
                backgroundColor: '#5ea6e4'
            },
            image: {
                uri: 'images/roulette/ss-50.svg',
                sizeMultiplier: 0.5,
                offsetX: 0,
                offsetY: -15,
                landscape: true
            }
        },
        {
            option: '300',
            style: {
                backgroundColor: '#5ea6e4'
            },
            image: {
                uri: 'images/roulette/lty-300.svg',
                sizeMultiplier: 0.5,
                offsetX: 0,
                offsetY: -15,
                landscape: true
            }
        },
        {
            option: '100',
            style: {
                backgroundColor: '#5ea6e4'
            },
            image: {
                uri: 'images/roulette/ss-100.svg',
                sizeMultiplier: 0.5,
                offsetX: 0,
                offsetY: -15,
                landscape: true
            }
        },
        {
            option: '100',
            style: {
                backgroundColor: '#5ea6e4'
            },
            image: {
                uri: 'images/roulette/lty-100.svg',
                sizeMultiplier: 0.5,
                offsetX: 0,
                offsetY: -15,
                landscape: true
            }
        },
    ], []);

    return (
        <Wheel
            fontFamily={"Chakra Petch"}
            mustStartSpinning={mustStartSpinning}
            prizeNumber={prizeNum}
            data={data}
            spinDuration={0.2}
            outerBorderColor={"#c7c7c7"}
            fontSize={30}
            fontWeight={"bold"}
            textColors={['white']}
            radiusLineWidth={1}
            radiusLineColor={"#c7c7c7"}
            outerBorderWidth={10}
            innerBorderWidth={0}
            startingOptionIndex={0}
            pointerProps={{
                src: "/images/roulette/pointer.svg",
            }}
            onStopSpinning={onStopSpinning}
        />
    );
};

const MemoPrizeWheel = React.memo(PrizeWheel, (prevProps, nextProps) => prevProps.mustStartSpinning === nextProps.mustStartSpinning);

export default MemoPrizeWheel;