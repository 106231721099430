import React, { useState, useRef, useCallback } from 'react';
import { Tooltip, Subheadline } from "@telegram-apps/telegram-ui";
import MemoizedAvatar from "./MemoizedAvatar";

const TooltipProfile = ({ user }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const centerButtonRef = useRef(null);

    // Мемоизируем обработчик клика
    const handleClick = useCallback(() => {
        setShowTooltip((prev) => !prev);
    }, []);

    return (
        <div>
            <div ref={centerButtonRef} onClick={handleClick}>
                <MemoizedAvatar
                    src={user?.username ? `https://unavatar.io/telegram/${user?.username}` : 'https://unavatar.io/telegram/empty'}
                    size={48}
                />
            </div>

            {showTooltip && (
                <Tooltip
                    className="tooltip-profile"
                    mode="dark"
                    placement="top"
                    targetRef={centerButtonRef}
                >
                    <div className="user-info">
                        <div className="d-flex gap-2 align-items-center">
                            <MemoizedAvatar
                                src={`https://unavatar.io/telegram/${user?.username}`}
                                size={48}
                            />
                            <div className="d-flex flex-column">
                                <Subheadline>
                                    {user?.firstName} {user?.lastName}
                                </Subheadline>
                                <span className="subtitle-color">@{user?.username}</span>
                            </div>
                        </div>
                    </div>
                </Tooltip>
            )}
        </div>
    );
};

export default TooltipProfile;