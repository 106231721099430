import {getGameApi, getGamesApi,} from "../../helpers/real_api_helper";
import {setGame, setGames} from "./reducer";


export const getGames = () => async (dispatch) => {
    try {
        getGamesApi()
            .then(res => {
                dispatch(setGames(res.data))
            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => {
            })
    }
    catch (error) {

    }
}

export const getGame = (id) => async (dispatch) => {
    try {
        getGameApi(id)
            .then(res => {
                dispatch(setGame(res.data))
            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => {
            })
    }
    catch (error) {

    }
}