import {createInvoiceLinkApi, getCountsApi} from "../../helpers/real_api_helper";
import {setCounters, setResetChance, setShowConfetti, setTelegramInvoiceLink, setTelegramStarsLoading} from "./reducer";
import {toast} from "react-toastify";
import {useSelector} from "react-redux";

export const getCounters = () => async (dispatch) => {
    try {
        const res = await getCountsApi();
        dispatch(setCounters(res.data));
    } catch (error) {
        console.error(error);
    }
}

export const payWithTelegramStars = (type, payload) => async (dispatch) =>{
    let data = {
        type: type,
        payload: payload
    }
    dispatch(setTelegramStarsLoading(true))
    createInvoiceLinkApi(data)
        .then(res => {
            dispatch(setTelegramInvoiceLink(res.data.link))
            window.Telegram.WebApp.openInvoice(res.data.link)
        })
        .catch(err => {
            toast.warning(err.data.message)
        })
        .finally(() => {
            dispatch(setTelegramStarsLoading(false))
        })
}


let confettiTimeout;

export const showConfetti = () => async (dispatch) => {
    dispatch(setShowConfetti(true));

    if (confettiTimeout) {
        clearTimeout(confettiTimeout);
    }

    confettiTimeout = setTimeout(() => {
        dispatch(setShowConfetti(false));
    }, 3000);
}


export const resetSlider = () => async (dispatch) => {
    dispatch(setResetChance(true));

    setTimeout(() => {
        dispatch(setResetChance(false));
    }, 100);
}

export const updateCounters = (data, counters) => async (dispatch) => {
    let counters_new = {...counters}
    counters_new.count_available_tasks = data.count_available_tasks

    dispatch(setCounters(counters_new))
}

