import {Card, Subheadline} from "@telegram-apps/telegram-ui";
import Task from "../../Components/custom/Task";
import ModalTask from "../../Components/custom/ModalTask";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getTasks} from "../../slices/tasks/thunk";
import TaskSkeleton from "./TaskSkeleton";
import {AnimatePresence, motion} from "framer-motion";

const Tasks = () => {
    const [selectedTask, setTask] = useState(null)
    const dispatch = useDispatch()
    const {tasks, showSkeleton, loaded, shouldUpdate} = useSelector(state => state.Task)
    const {counters} = useSelector(state => state.App)

    useEffect(() => {
        if (!loaded)
            dispatch(getTasks())
    }, [dispatch, shouldUpdate, loaded])

    const modalClose = () => {
        setTask(null)
    }

    const selectTask = (el) => {
        if (el.is_available)
            setTask(el)
    }
    return (
        <>
            <ModalTask
                task={selectedTask}
                isOpen={selectedTask}
                modalClose={modalClose}
                headline={"Complete all tasks to receive reward"}
            />
            <Subheadline className={"text-grey ms-3"}>Tasks</Subheadline>
            <Card className={"d-flex flex-column mt-2 p-3 gap-3"}>
                {!loaded && Array.from({ length: counters?.count_all_tasks ?? 4 }, (_, index) => (
                    <TaskSkeleton key={index} />
                ))}

                <AnimatePresence>
                    {loaded && tasks.map((el, i) => (
                        <motion.div
                            key={el.id}
                            layout
                        >
                            <Task
                                onClick={(e) => selectTask(el, e)}
                                key={i}
                                task={el}
                            />
                        </motion.div>
                    ))}
                </AnimatePresence>

            </Card>
        </>

    )
}

export default Tasks