import Drawer from "../Drawer";
import {Button, Headline} from "@telegram-apps/telegram-ui";
import React from "react";
import work from "../../../assets/lottie/dotlottie/work.lottie";
import {DotLottieReact} from "@lottiefiles/dotlottie-react";
import unlock from "../../../assets/lottie/dotlottie/unlock.lottie";
import {useTaskRow} from "../../../hooks/useTaskRow";
import OptimizedLottie from "../OptimizedLottie";

const ModalGameTasks = (props) => {
    const {isOpen, modalClose, selectedGame, checkCompletion, loading} = props
    const task = selectedGame?.task
    const {getTaskRow} = useTaskRow()

    return (
        <Drawer
            isOpen={isOpen}
            onClose={modalClose}
        >
            <div className={"d-flex flex-column p-3"}>
                <div className={"d-flex justify-content-center mb-4"}>
                    <OptimizedLottie
                        src={work}
                        loop
                        autoplay
                        style={{
                            height: 100,
                            width: 100,
                        }}
                    />
                </div>
                <div className={"d-block"}>
                    <Headline
                        className={"text-center"}
                        weight={1}
                    >
                        Complete all tasks to play {selectedGame?.name}
                    </Headline>
                </div>
                <div className={"requirements d-flex flex-column py-5 px-3 gap-3"}>
                    {task?.conditions && selectedGame?.task?.conditions.map((el, i) => (
                        getTaskRow(el, i)
                    ))}

                </div>
                <div className={"d-block"}>
                    <Button
                        loading={loading}
                        size={"l"}
                        before={
                            <OptimizedLottie
                                src={unlock}
                                loop
                                autoplay
                                style={{
                                    height: 20,
                                    width: 20,
                                }}
                            />
                        }
                        mode={'bezeled'}
                        stretched
                        onClick={checkCompletion}
                    >
                        Check completion
                    </Button>
                </div>
            </div>
        </Drawer>
    )
}

export default ModalGameTasks