import {Button, Caption, Card, FixedLayout, Headline, LargeTitle, Subheadline, Title} from "@telegram-apps/telegram-ui";
import React, {useState} from "react";
import giftLottie from "../../../../assets/lottie/gift.json";
import Lottie from "lottie-react";
import PrizeTable from "../../../../pages/Home/PrizeTable";
import Drawer from "../../Drawer";
import {formatNumber} from "../../../../helpers/format_helper";
import {cancelParticipateRaffleApi} from "../../../../helpers/real_api_helper";
import {useDispatch} from "react-redux";
import {getRaffles, getTopRaffles} from "../../../../slices/raffles/thunk";
import {setSelectedInnerType, setSelectedTab} from "../../../../slices/raffles/reducer";
import {toast} from "react-toastify";
import Prizes from "../Parts/Prizes";
import {DotLottieReact} from "@lottiefiles/dotlottie-react";
import free from "../../../../assets/lottie/dotlottie/fire.lottie";
import IconLottyStar from "../../../icons/IconLottyStar";
import IconTelegramStar from "../../../icons/IconTelegramStar";

const ModalRaffleParticipated = (props) => {
    const {isOpen, modalClose, selectedRaffle} = props
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()


    const cancelParticipate = () => {
        setLoading(true)
        cancelParticipateRaffleApi(selectedRaffle)
            .then(res => {
                modalClose()
            })
            .catch(err => {
                toast.warning(err.data.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }
    return (
        <Drawer
            onClose={modalClose}
            isOpen={isOpen}
        >
            <div
                style={{
                    paddingBottom: 90
                }}
                className={"d-flex w-100 flex-column gap-3 justify-content-center align-items-center"}
            >
                <div className={""}>
                    <Title>Lotty #{selectedRaffle?.id}</Title>
                </div>
                <div className={"task-icon"}>
                    <Lottie
                        style={{
                            width: 160,
                            height: 80
                        }}
                        animationData={giftLottie} loop={false}
                    />
                </div>
                <div className={"d-flex flex-row gap-2 align-items-center"}>
                    <Prizes prizes={selectedRaffle?.prizes} />
                </div>
                <div className={"d-flex gap-3"}>
                    <Card className={"p-3 d-flex flex-column align-items-center gap-1"}>
                        <LargeTitle weight={1}>{selectedRaffle?.ticket_number}</LargeTitle>
                        <Caption className={"subtitle-color"}>Your ticket number</Caption>
                    </Card>
                    <Card className={"p-3 d-flex flex-column align-items-center gap-1"}>
                        <LargeTitle weight={1}>x{selectedRaffle?.raffle_chance}</LargeTitle>
                        <Caption className={"subtitle-color"}>Chance</Caption>
                    </Card>
                </div>
                <div className={"d-flex flex-column text-center"}>
                    <Subheadline weight={2}>
                        Participants
                    </Subheadline>
                    <span
                        className={"ton_num"}
                    >
                        👥 {formatNumber(selectedRaffle?.current_participants)} / {formatNumber(selectedRaffle?.max_participants)}
                    </span>
                    <Caption className={"subtitle-color mt-1"}>
                        The raffle will start once all participants have joined.
                    </Caption>
                </div>
                <Headline
                    className={"text-center"}
                    level="1"
                    weight="2"
                >
                   Winner places
                </Headline>

                <PrizeTable
                    matrix={selectedRaffle?.matrix}
                />
            </div>
            <FixedLayout
                className={"p-3 safe-padding-bottom main-bg"}
                vertical="bottom">
                <div className={"d-flex flex-column gap-2 w-100 text-center"}>
                    <Button
                        loading={loading}
                        onClick={cancelParticipate}
                        className={"bg-danger w-100"}
                    >
                        Cancel
                    </Button>
                </div>
            </FixedLayout>

        </Drawer>
    )
}

export default ModalRaffleParticipated