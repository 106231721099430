import {MenuItem} from "@mui/material";
import Select from "@mui/material/Select";
import React, {useEffect, useState} from "react";
import {Caption, Spinner, Subheadline, Text} from "@telegram-apps/telegram-ui";
import {useDispatch, useSelector} from "react-redux";
import {getRaffles} from "../../slices/raffles/thunk";
import {setSelectedInnerType} from "../../slices/raffles/reducer";

const RaffleFilter = (props) => {
    const [filterText, setFilterText] = useState(false)
    const [options, setOptions] = useState( [
        {name: "All", id: "all"},
        {name: "Joined", id: "joined"},
        {name: "Available", id: "not_joined"},
    ])
    const {selectedTab, raffles, selectedInnerType, loading} = useSelector(state => state.Raffles)
    const dispatch = useDispatch()


    useEffect(() => {
        if (selectedTab) {
            let options
            if (selectedTab === 'active')
                options = [
                    {name: "All", id: "all"},
                    {name: "Joined", id: "joined"},
                    {name: "Available", id: "not_joined"},
                ]
            else if (selectedTab === 'finished')
                options = [
                    {name: "All", id: "all"},
                    {name: "Won", id: "joined"},
                    {name: "Missed", id: "not_joined"},
                ]
            setOptions(options)
        }
    }, [selectedTab]);
    const handleChange = (e) => {
        dispatch(setSelectedInnerType(e.target.value))
        dispatch(getRaffles(1, selectedTab, e.target.value, false))
    }

    useEffect(() => {
        let variants
        if (selectedTab === 'active') {
            variants = [
                {name: "All Lotty", id: "all"},
                {name: "Participating", id: "joined"},
                {name: "Available Lotty", id: "not_joined"},
            ]
        }
        else if (selectedTab === 'finished')
            variants = [
                {name: "All Lotty", id: "all"},
                {name: "Won Lotty", id: "joined"},
                {name: "Missed Lotty", id: "not_joined"},
            ]
        let variant = variants.find(el => el.id === selectedInnerType)
        setFilterText(variant.name)
    }, [selectedInnerType, selectedTab])

    return (
        <div className={`d-flex w-100 justify-content-between align-items-center ps-4 pe-3 raffle-filter ${selectedTab == 'active' ? 'active-filters' : ''}`}>
            <div className={"d-flex gap-2 align-items-center"}>
                <Caption className={"subtitle-color"} weight={2}>{filterText}</Caption>
            </div>

            <Select className={"raffle-filter-select"} id="filter-available" value={selectedInnerType} onChange={handleChange}>
                {options.map((el, i) => (
                    <MenuItem key={i} value={el.id}>
                        <div className={"d-flex gap-1 align-items-center"}>
                            <Caption weight={2} className={"subtitle-color"}>{el.name}</Caption>
                        </div>
                    </MenuItem>
                ))}
            </Select>
        </div>
    )
}

RaffleFilter.whyDidYouRender = true;

export default RaffleFilter