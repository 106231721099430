import {getTransactionsApi} from "../../helpers/real_api_helper";
import {setTransactions} from "./reducer";

export const getTransactions = (page = 1, append = false) => async (dispatch) => {
    try {
        let res = await getTransactionsApi(page)
        if (res) {
            dispatch(setTransactions({
                meta: res.meta,
                data: res.data,
                append
            }))
        }
    }
    catch (error) {
        console.log(error)
    }
}
