import {createSlice} from "@reduxjs/toolkit";

export const initialState = {
   balance: null,
    loaded: false
}

const appSlice = createSlice({
    name: "balance",
    initialState,
    reducers: {
       setBalance(state, action) {
           state.balance = action.payload
           state.loaded = true
       },
    }
})

export const {
    setBalance
} = appSlice.actions

export default appSlice.reducer