import React from 'react';
import { Routes, Route, useLocation } from "react-router-dom";
import { authProtectedRoutes } from "./authRoutes";
import Layout from "../Layouts";
import AuthProtected from "./AuthProtected";
import { AnimatePresence, motion } from "framer-motion";
import {SocketProvider} from "../context/SocketProvider";
import WelcomeStories from "../Components/custom/WelcomeStories";
import SocketEventListener from "../Components/custom/SocketEventListener";

const Index = () => {
    const location = useLocation();

    return (
        <AuthProtected>
            <SocketProvider>
                <SocketEventListener />
                <React.Fragment>
                    <AnimatePresence mode="wait">
                        <Routes location={location} key={location.pathname}>
                            {authProtectedRoutes.map((route, index) => (
                                <Route
                                    path={route.path}
                                    element={
                                                <Layout>
                                                    <motion.div
                                                        initial={{ opacity: 0 }}
                                                        animate={{ opacity: 1 }}
                                                        exit={{ opacity: 0 }}
                                                        transition={{ duration: 0.2 }}
                                                    >
                                                        {route.component}
                                                    </motion.div>
                                                </Layout>

                                    }
                                    key={index}
                                    exact={true}
                                />
                            ))}
                        </Routes>
                    </AnimatePresence>
                </React.Fragment>
            </SocketProvider>
        </AuthProtected>
    );
};

export default Index;