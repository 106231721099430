import React, {useEffect, useState} from "react";
import {Button, Spinner, Subheadline, TabsList, Title} from "@telegram-apps/telegram-ui";
import {TabsItem} from "@telegram-apps/telegram-ui/dist/components/Navigation/TabsList/components/TabsItem/TabsItem";
import {useDispatch, useSelector} from "react-redux";
import {getRaffles} from "../../slices/raffles/thunk";
import {setSelectedTab, setShowSkeleton} from "../../slices/raffles/reducer";
import TopRaffles from "../../pages/Home/TopRaffles";
import first from "../../assets/lottie/dotlottie/fire.lottie";
import third from "../../assets/lottie/dotlottie/brilliant.lottie";
import RaffleFilter from "../../pages/Home/RaffleFilter";
import HorizontalLotteryCard from "./Raffle/HorizontalLotteryCard";
import HorizontalFinishedLotteryCard from "./Raffle/HorizontalFinishedLotteryCard";
import OptimizedLottie from "./OptimizedLottie";
import {AnimatePresence, motion} from "framer-motion";
import NewVerticalCard from "./Raffle/NewVerticalCard";

const RaffleList = () => {

    const dispatch = useDispatch()
    const [stateTab, setStateTab] = useState('active')
    const {raffles, meta, loading, selectedRaffle, showSkeleton, loaded, selectedTab, selectedInnerType, topRaffles} = useSelector(state => state.Raffles)

    useEffect(() => {
        dispatch(getRaffles(1, selectedTab, selectedInnerType))
    }, [])

    const showMore = () => {
        dispatch(getRaffles(meta.current_page + 1, selectedTab ,selectedInnerType,true))
    }


    const tabs = [
        {name: "Active", id: 'active'},
        {name: "Completed", id: 'finished'},
    ]

    const changeTab = (el) => {
        if (selectedTab !== el) {
            setStateTab(el)
            dispatch(setSelectedTab(el))
            dispatch(setShowSkeleton(true))
            dispatch(getRaffles(1, el))
        }
    }

    return (
        <>
            <div className={"tabs px-5 py-3"}>
                <TabsList>
                    {tabs && tabs.map((el, i) => (
                        <TabsItem
                            key={i}
                            onClick={() => changeTab(el.id)}
                            selected={selectedTab === el.id}
                        >
                            <div className={"d-flex justify-content-center align-items-center gap-2"}>
                                <OptimizedLottie
                                    src={el.id === 'active' ? first : third}
                                    loop
                                    autoplay
                                    style={{
                                        width: 20,
                                        height: 20
                                    }}
                                />
                                {el.name}

                            </div>
                        </TabsItem>
                    ))}
                </TabsList>
            </div>
            <TopRaffles />
            <RaffleFilter/>

                {loaded && !raffles?.length &&
                    <div className={"text-center"}>
                        <Subheadline className={"subtitle-color"}>There is no Lotty here</Subheadline>
                    </div>

                }
                {!loaded &&
                    <div className={"d-flex justify-content-center"}>
                        <Spinner size={'l'} />
                    </div>
                }

                {stateTab === 'finished' ?
                    raffles && raffles.map((el, i) => (
                        <HorizontalFinishedLotteryCard
                            key={i}
                            current={el}
                        />
                    )):
                    <div className={"raffles-grid gap-2 p-2"}>
                    <AnimatePresence>
                        {raffles && raffles.map((el, i) => (
                            <motion.div
                                key={el.id}
                                initial={{ opacity: 0, y: 50 }} // Начальное скрытое состояние
                                whileInView={{
                                    opacity: 1,
                                    y: 0, // Конечное состояние
                                    transition: { duration: 0.5 }, // Плавное изменение
                                }}
                                viewport={{ once: true, margin: '0px 0px -20px 0px' }} // Анимация сразу при входе в вьюпорт, с отступом
                                exit={{ opacity: 0, y: 50 }} // Анимация при выходе
                                className="flex-grow-1"
                                layout
                            >
                                <NewVerticalCard
                                    current={el}
                                />
                            </motion.div>
                        ))}
                    </AnimatePresence>
                    </div>

                }

                {meta?.last_page > meta?.current_page &&
                    <div className={"pagination d-flex justify-content-center pt-2"}>
                        <Button
                            loading={loading}
                            onClick={showMore}
                            mode={"plain"}
                        >Show more
                        </Button>
                    </div>
                }
        </>

    )
}

export default RaffleList