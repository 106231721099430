import {Caption, Skeleton, Subheadline} from "@telegram-apps/telegram-ui";
import React from "react";

const TransactionSkeleton = () => {
    return (
        <div className={"transaction d-flex justify-content-between align-items-center"}>
            <div className={"d-flex flex-row gap-3"}>
                <Skeleton visible={true}>
                    <div style={{display:'block', height: 40, width: 40}}></div>
                </Skeleton>
                <Skeleton visible={true}>
                    <div className={"d-flex flex-column"}>
                        <Subheadline>Transaction title</Subheadline>
                        <Caption>12 Dec 2024</Caption>
                    </div>
                </Skeleton>
            </div>
            <div className={"d-flex bonus-amount align-items-center gap-2"}>
                <Skeleton visible={true}>
                    <span>
                    + 500
                    </span>
                </Skeleton>
                <Skeleton visible={true}>
                    <div
                        style={{display: 'block', height: 24, width: 24}}
                    ></div>
                </Skeleton>

            </div>
        </div>
    )
}

export default TransactionSkeleton