import Drawer from "../../Drawer";
import {useDispatch, useSelector} from "react-redux";
import { RandomReveal } from "react-random-reveal";
import {setSelectedRaffle, setShowWinnerModal, setWinnerData} from "../../../../slices/raffles/reducer";
import {Button, Caption, Card, FixedLayout, Headline, LargeTitle, Subheadline, Title} from "@telegram-apps/telegram-ui";
import finished from "../../../../assets/lottie/dotlottie/finished.lottie";
import open from "../../../../assets/lottie/dotlottie/open.lottie";
import {DotLottieReact} from "@lottiefiles/dotlottie-react";
import React, {useState} from "react";
import ordinal, {getPrize} from "../../../../helpers/raffle_helper";
import free from "../../../../assets/lottie/dotlottie/fire.lottie";
import IconLottyStar from "../../../icons/IconLottyStar";
import IconTelegramStar from "../../../icons/IconTelegramStar";
import CountUp from "react-countup";
import {setReward} from "../../../../slices/app/reducer";
import {showConfetti} from "../../../../slices/app/thunk";
import party from "../../../../assets/lottie/dotlottie/party.lottie";
import {getNotificationsApi, getRaffleApi, updateNotificationApi} from "../../../../helpers/real_api_helper";
import {toast} from "react-toastify";
import {getNotifications} from "../../../../slices/users/thunk";
import OptimizedLottie from "../../OptimizedLottie";
import FireworksParticles from "../../FireworksParticles";

const ModalPersonalWinner = () => {
    const {showWinnerModal, winnerData} = useSelector(state => state.Raffles)
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)

    const modalClose = ()  => {
        dispatch(setShowWinnerModal(false))
        updateNotificationApi(winnerData?.notification)
            .then(res => {
                dispatch(setWinnerData(null))
                dispatch(getNotifications())
            })
    }

    const getWinnerPrize = () => {
        let reward = [{
            reward_type: winnerData?.prize?.type,
            amount: winnerData?.prize?.amount,
            transaction_type: 'credit'
        }]
        dispatch(setReward(reward))
        dispatch(showConfetti())
        modalClose()
    }

    const showAllWinners = () => {
        setLoading(true)
       getRaffleApi(winnerData?.raffle)
           .then(res => {
               dispatch(setSelectedRaffle(res.data));
           })
           .catch(err => {
                toast.warning(err.message)
           })
           .finally(() => {
                setLoading(false)
           })
    }



    return (
        <Drawer
            customClass={"winner-drawer"}
            isOpen={showWinnerModal}
            onClose={modalClose}
        >
            <FireworksParticles />
            <div className={"d-flex gap-3 justify-content-center flex-column"}
                 style={{
                     paddingBottom: 160,
                     minHeight: "80vh"
                 }}
            >
                <div className={"d-flex gap-2 flex-column text-center"}>
                    <LargeTitle
                        style={{
                            fontFamily: "Chakra Petch"
                        }}
                    >Congratulations!</LargeTitle>
                    <Title
                        style={{
                            fontFamily: "Chakra Petch"
                        }}
                    >You are the winner of Lotty #{winnerData?.raffle}</Title>
                </div>
                <div className={"d-flex gap-3 justify-content-center"}>
                    <Card className={"p-3 d-flex flex-column align-items-center gap-1"}>
                        <LargeTitle weight={1}>
                            <div className={"d-flex justify-content-center align-items-center winner-prize"}>
                                {getPrize(winnerData?.prize?.type, winnerData?.prize?.amount, true, true)}
                            </div>
                        </LargeTitle>
                        <Caption className={"subtitle-color"}>Your prize</Caption>
                    </Card>
                    <Card className={"p-3 d-flex flex-column align-items-center gap-1"}>
                        <LargeTitle weight={1}>{winnerData?.ticket_number}</LargeTitle>
                        <Caption className={"subtitle-color"}>Your lucky number</Caption>
                    </Card>
                </div>
                <div className={"d-block text-center"}>
                    <Headline className={"subtitle-color"}>
                        You ranked {ordinal(winnerData?.place)} out of {winnerData?.max_participants} places
                    </Headline>
                </div>
            </div>
            <FixedLayout
                className={"p-3 safe-padding-bottom main-bg"}
                vertical="bottom">
                <div className={"d-flex flex-column gap-2 w-100 text-center"}>
                    <Button
                        onClick={() => getWinnerPrize()}
                        mode={'gray'}
                        before={
                            <IconLottyStar
                                style={{
                                    height: 15,
                                    width: 15
                                }}
                            />
                        }
                        className={"w-100"}
                    >
                        Get prize
                    </Button>
                    <Button
                        onClick={() => showAllWinners()}
                        mode={'gray'}
                        loading={loading}
                        style={{
                            color: '#fdb345'
                        }}
                        before={
                            <IconTelegramStar
                                style={{
                                    height: 20,
                                    width: 20
                                }}
                            />
                        }
                        className={"w-100"}
                    >
                        Show all winners
                    </Button>
                </div>
            </FixedLayout>
        </Drawer>
    )
}

export default ModalPersonalWinner