import one from "../assets/lottie/dotlottie/1.lottie";
import two from "../assets/lottie/dotlottie/2.lottie";
import three from "../assets/lottie/dotlottie/3.lottie";
import React from "react";
import MemoDotLottie from "../Components/custom/MemoDotLottie";


export const formatNumber = (num) => {
    if (!num) return '0';

    if (num >= 1000 && num < 1000000) {
        return parseFloat((num / 1000).toFixed(1)).toString() + 'K'; // Пример: 1500 -> 1.5K
    } else if (num >= 1000000) {
        return parseFloat((num / 1000000).toFixed(1)).toString() + 'M'; // Пример: 1,500,000 -> 1.5M
    }

    return Number(num).toLocaleString('en-US');
}
const formatMatrix = (matrix) => {

}

export const emojiConverter = (value) => {
    switch (value) {
        case 1:
            return (
                <MemoDotLottie
                    src={one}
                    loop
                    autoplay
                    style={{
                        height: 15,
                        width: 15,
                    }}
                />
            )
        case 2:
            return (
                <MemoDotLottie
                    src={two}
                    loop
                    autoplay
                    style={{
                        height: 15,
                        width: 15,
                    }}
                />
            )
        case 3:
            return (
                <MemoDotLottie
                    src={three}
                    loop
                    autoplay
                    style={{
                        height: 15,
                        width: 15,
                    }}
                />
            )
        case 4:
            return "4️⃣";
        default:
            return value; // Возвращаем значение по умолчанию, если не найдено соответствие
    }
}