import {Caption, Skeleton, Subheadline} from "@telegram-apps/telegram-ui";
import IconLottyStar from "../../Components/icons/IconLottyStar";
import React from "react";

const FriendSkeleton = () => {
    return (
        <div style={{height: 44}} className={"task d-flex flex-row justify-content-between align-items-center"}>
            <div className={"d-flex flex-row gap-2 align-items-center w-75"}>

                    <div className={""}>
                        <Skeleton style={{height:40, width: 40}} visible={true}>
                        </Skeleton>
                    </div>


                <div className={"d-flex flex-column"}>
                    <Skeleton visible={true}>
                        <Subheadline className={"text-break"} weight={2}>
                        </Subheadline>
                    </Skeleton>
                    <Skeleton visible={true}>
                        <Caption weight={3}>
                            12 Dec 2024
                        </Caption>
                    </Skeleton>
                </div>
            </div>
            <Skeleton visible={true}>
                <div className={"d-flex bonus-amount align-items-center gap-1"}>
                                    <span className={"text-warning"}>
                                        + 500
                                    </span>
                    <span>
                                        <IconLottyStar className={"transaction-icon"} />
                                    </span>
                </div>
            </Skeleton>
        </div>
    )
}

export default FriendSkeleton