import { Avatar, Badge, Tabbar } from "@telegram-apps/telegram-ui";
import React from 'react';
import { useNavigate } from "react-router-dom";
import { GiRoundStar, GiWallet, GiEmerald } from "react-icons/gi";
import { RiLinkM } from "react-icons/ri";
import { useSelector } from "react-redux";
import TooltipProfile from "../Components/custom/Profile/TooltipProfile";

const Footer = () => {
    const navigate = useNavigate();
    const { currentTab, counters } = useSelector((state) => state.App);
    const { user } = useSelector((state) => state.Auth);

    const tabs = [
        {
            id: 'rewards',
            text: 'Earn',
            Icon: (
                <>
                    {counters?.count_available_tasks > 0 && (
                        <Badge
                            className="position-absolute start-50"
                            mode="critical"
                            type="number"
                        >
                            {counters?.count_available_tasks}
                        </Badge>
                    )}
                    <GiRoundStar size={26} />
                </>
            ),
            path: "/rewards"
        },
        { id: 'lottys', text: 'Lotty', Icon: <GiEmerald size={26} />, path: "/lotty" },
        { id: 'friends', text: 'Invite', Icon: <RiLinkM size={26} />, path: "/friends" },
        { id: 'wallet', text: 'Wallet', Icon: <GiWallet size={26} />, path: "/wallet" }
    ];

    const changeTab = (tab) => {
        if (window.Telegram.WebApp)
            window.Telegram.WebApp.HapticFeedback.impactOccurred('soft');
        navigate(tab.path);
    };

    return (
        <Tabbar className="footer" style={{ zIndex: 10, position: "sticky" }}>
            {tabs.slice(0, 2).map((el, i) => (
                <Tabbar.Item
                    className="tabbar-item"
                    key={i}
                    text={el.text}
                    selected={el.id === currentTab}
                    onClick={() => changeTab(el)}
                >
                    {el.Icon}
                </Tabbar.Item>
            ))}

            {/* Центральная кнопка с тултипом */}
            <Tabbar.Item>
                <TooltipProfile user={user} />
            </Tabbar.Item>

            {tabs.slice(2).map((el, i) => (
                <Tabbar.Item
                    className="tabbar-item"
                    key={i + 2}
                    text={el.text}
                    selected={el.id === currentTab}
                    onClick={() => changeTab(el)}
                >
                    {el.Icon}
                </Tabbar.Item>
            ))}
        </Tabbar>
    );
};

export default Footer;