import Drawer from "../Drawer";
import {DotLottieReact} from "@lottiefiles/dotlottie-react";
import win from "../../../assets/lottie/dotlottie/win.lottie";
import {Button, Subheadline, Title} from "@telegram-apps/telegram-ui";
import React from "react";
import IconTon from "../../icons/IconTon";
import IconLottyCoin from "../../icons/IconLottyCoin";
import {getPrize} from "../../../helpers/raffle_helper";
import OptimizedLottie from "../OptimizedLottie";

const ModalGamePrize = (props) => {
    const {isOpen, modalClose, prize} = props
    return (
        <Drawer
            isOpen={isOpen}
            onClose={modalClose}
        >
            <div className={"d-flex flex-column gap-3 justify-content-center align-items-center p-4 pt-0"}>
                <div className={"welcome-icon"}>
                    <OptimizedLottie
                        style={{
                            width: 150,
                            height: 75
                        }}
                        src={win}
                        loop
                        autoplay
                    />
                </div>
                <div className={"d-flex align-items-center text-center"}>
                    <Title weight={1}>
                        Congratulations! You won!
                    </Title>
                </div>
                <div className={"prize d-flex gap-1"}>
                    {getPrize(prize?.token, prize?.amount, true)}
                </div>
                <div className={"d-block text-center"}>
                    <Subheadline className={"subtitle-color"} weight={3}>
                        The prize has been transferred to your wallet.<br/> Play this game every 6 hours and increase your chances of winning valuable rewards!
                    </Subheadline>

                </div>
                <div className={"d-block w-100"}>
                    <Button
                        stretched
                        onClick={modalClose}
                    >
                        Got it!
                    </Button>
                </div>

            </div>
        </Drawer>
    )
}

export default ModalGamePrize