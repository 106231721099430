import {Wheel} from "react-custom-roulette";
import {Button, Caption, Card, FixedLayout, Title} from "@telegram-apps/telegram-ui";
import React, {useCallback, useMemo, useState} from "react";
import {getRandomPrize} from "../../../helpers/real_api_helper";
import {useDispatch, useSelector} from "react-redux";
import {setIncreaseChanceCoins, setShowConfetti} from "../../../slices/app/reducer";
import IncreaseChance from "../IncreaseChance";
import ModalGame from "./ModalGame";
import {setGame, setMustSpin, setPrizeNum} from "../../../slices/games/reducer";
import ModalGamePrize from "./ModalGamePrize";
import {getBalance} from "../../../slices/balance/thunk";
import {resetSlider, showConfetti} from "../../../slices/app/thunk";
import MemoPrizeWheel from "../MemoPrizeWheel";
import {toast} from "react-toastify";
import LevelRewards from "../LevelRewards";


const PrizeWheel = () => {
    const dispatch = useDispatch()
    const {selectedGame, mustStartSpinning} = useSelector(state => state.Games)
    const {increaseChanceCoins} = useSelector(state => state.App)
    const [openDailyModal, setOpenDailyModal] = useState(false)
    const [showPrizeModal, setShowPrizeModal] = useState(false)
    const [winnerPrize, setWinnerPrize] = useState(null)

    const handleSpinClick = (paymentMethod = null, invoiceLink = null) => {
        dispatch(setShowConfetti(false))
        getRandomPrize(selectedGame, increaseChanceCoins, paymentMethod, invoiceLink)
            .then(res => {
                if (!mustStartSpinning) {
                    dispatch(setPrizeNum(res.data.prizeNum))
                    setWinnerPrize(res.data.prize)
                    dispatch(setMustSpin(true))
                    dispatch(setGame({
                        ...selectedGame,
                        next_available_at: res.data.next_available_at
                    }))
                    dispatch(resetSlider())
                    dispatch(getBalance())
                }
            })
            .catch(err => {
                toast.warning(err.data.message)
            })
    }

    const onStopWheel = () => {
        dispatch(setMustSpin(false))
        dispatch(showConfetti())
        setShowPrizeModal(true)
    }

    const modalClose = () => {
        setOpenDailyModal(false)
    }

    const openGameModal = () => {
        setOpenDailyModal(true)
    }

    const modalPrizeClose = () => {
        setShowPrizeModal(false)
    }

    return (
        <>
            <ModalGame
                isOpen={openDailyModal}
                modalClose={modalClose}
                selectedGame={selectedGame}
                nextAvailableAt={selectedGame?.next_available_at}
                onPaySuccess={handleSpinClick}
            />

            <ModalGamePrize
                prize={winnerPrize}
                isOpen={showPrizeModal}
                modalClose={modalPrizeClose}
            />

            <div className={"text-center d-flex gap-2 mt-2 pb-2 flex-column"}>
                <Title>Daily spinner</Title>
                <Caption>You have the opportunity to receive a random bonus every 6 hours</Caption>
            </div>
            <div className={"d-flex align-items-center wheel flex-column w-100"}>
                <div className={"wheel"}>
                    <MemoPrizeWheel
                        onStopSpinning={onStopWheel}
                    />
                </div>
                <div className={"mt-2 w-100"}>
                    <LevelRewards />
                </div>
            </div>
            <FixedLayout
                className={"px-3 safe-padding-bottom"}
                vertical="bottom">
                <Button
                    className={"text-uppercase disabled"}
                    stretched
                    onClick={selectedGame.next_available_at !== null ? () => openGameModal() : () => handleSpinClick()}
                >
                    Take it!
                </Button>
            </FixedLayout>

        </>
    )
}

export default PrizeWheel