import Drawer from "../../Drawer";
import React, {useEffect, useRef, useState} from "react";
import {Button, Card, Headline, IconButton, LargeTitle, Slider, Subheadline} from "@telegram-apps/telegram-ui";
import {participateToRaffle} from "../../../../slices/raffles/thunk";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import IconLottyCoin from "../../../icons/IconLottyCoin";
import {formatNumber} from "../../../../helpers/format_helper";
import {DotLottieReact} from "@lottiefiles/dotlottie-react";
import chuvak from "../../../../assets/lottie/dotlottie/chuvak.lottie";
import {setFinalModalData, setShowConfetti} from "../../../../slices/app/reducer";
import pin from "../../../../assets/lottie/dotlottie/pin.lottie";
import IncreaseChance from "../../IncreaseChance";
import OptimizedLottie from "../../OptimizedLottie";
import {payWithTelegramStars} from "../../../../slices/app/thunk";

const ModalSelectNumber = (props) => {
    const {isOpen, modalClose, selectedRaffle, paymentType} = props
    const [raffleTicketNumber, setTicketNumber] = useState(false)
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const {increaseChanceCoins, telegramInvoiceLink, telegramStarsLoading} = useSelector(state => state.App)
    const {user} = useSelector(state => state.Auth)
    const intervalRef = useRef(null);
    const holdTimeout = useRef(null);


    const participateRaffle = async () => {
        if (paymentType === 'telegram_stars') {
            let payload = JSON.stringify({
                id: selectedRaffle.id,
                user_id: user.id
            })
            await dispatch(payWithTelegramStars('lotty', payload))
        }
        else {
           await participate()
        }
    }
    const participate = async () => {
        let data = {
            ticketNumber: raffleTicketNumber,
            lotty_coins: increaseChanceCoins,
            payment_type: paymentType,
            invoice_link: telegramInvoiceLink
        };

        setLoading(true);

        try {
            await dispatch(participateToRaffle(data, selectedRaffle));

            await modalClose();

            let finalModalData = {
                title: "Congratulations",
                description: `<p class="text-center">
                You’re now part of <b>Lotty #${selectedRaffle.id}</b>
            </p>
            <ul>
                <li>Your participant number is <b>${raffleTicketNumber}</b> out of <b>${selectedRaffle.max_participants}</b>.</li>
                <li>The <b>Lotty #${selectedRaffle.id}</b> will take place when all participants are signed up.</li>
            </ul>
            <p class="text-center subtitle-color">Be ready for a notification with the outcome and your prize!</p>`
            };

            await dispatch(setShowConfetti(true));
            await dispatch(setFinalModalData(finalModalData));
        } catch (err) {
            // Здесь можно обработать ошибку, полученную из API
            toast.warning(err.data.message);
        } finally {
            setLoading(false);
        }
    }

    const selectRandomTicket = () => {
        const took = selectedRaffle?.took_numbers; // Список занятых номеров
        const range = [1, selectedRaffle?.max_participants]; // Диапазон доступных номеров

        // Генерация случайного числа в диапазоне
        const getRandomNumber = (min, max) => {
            return Math.floor(Math.random() * (max - min + 1)) + min;
        };

        // Функция для выбора случайного номера, который не входит в список занятых номеров
        if (selectedRaffle?.max_participants !== selectedRaffle?.current_participants) {
            let randomTicket;
            do {
                randomTicket = getRandomNumber(range[0], range[1]);
            } while (took.includes(randomTicket));
            setTicketNumber(randomTicket);
        }
    };

    useEffect(() => {
        if (isOpen) {
            selectRandomTicket()
        }
    }, [isOpen]);


    const handleSelectNumber = (value) => {
        setTicketNumber(value)
    }

    useEffect(() => {
        if (raffleTicketNumber < selectedRaffle?.max_participants && raffleTicketNumber > 0) {
            window.Telegram.WebApp.HapticFeedback.impactOccurred('soft')
        }
    }, [raffleTicketNumber])

    const checkIfNumberHasBeenTaken = () => {
        if (selectedRaffle?.took_numbers?.includes(raffleTicketNumber))
            return true
        return false;
    }

    const startAction = (func) => {
        holdTimeout.current = setTimeout(() => {
            if (!intervalRef.current) {
                intervalRef.current = setInterval(() => {
                    func();
                }, 1); // Интервал в 1 мс
            }
        }, 500); // Задержка в 100 мс перед началом интервала
    };

    const stopAction = () => {
        clearTimeout(holdTimeout.current); // Очищаем задержку, если не успел запуститься
        if (intervalRef.current) {
            clearInterval(intervalRef.current); // Очищаем таймер
            intervalRef.current = null; // Сбрасываем ref
        }
    };

    const decrementTicketNumber = () => {
        setTicketNumber(prev => {
            // Фильтруем took_numbers, чтобы получить только свободные номера
            const availableNumbers = Array.from({ length: selectedRaffle.max_participants }, (_, i) => i + 1)
                .filter(num => !selectedRaffle.took_numbers.includes(num));

            // Находим индекс текущего значения в массиве доступных номеров
            const currentIndex = availableNumbers.indexOf(prev);

            // Переходим к предыдущему доступному номеру или возвращаем последний
            const newIndex = currentIndex > 0 ? currentIndex - 1 : availableNumbers.length - 1;
            return availableNumbers[newIndex];
        });
    };

    const incrementTicketNumber = () => {
        setTicketNumber(prev => {
            // Фильтруем took_numbers, чтобы получить только свободные номера
            const availableNumbers = Array.from({ length: selectedRaffle.max_participants }, (_, i) => i + 1)
                .filter(num => !selectedRaffle.took_numbers.includes(num));

            // Находим индекс текущего значения в массиве доступных номеров
            const currentIndex = availableNumbers.indexOf(prev);

            // Переходим к следующему доступному номеру или возвращаем первый
            const newIndex = currentIndex < availableNumbers.length - 1 ? currentIndex + 1 : 0;
            return availableNumbers[newIndex];
        });
    };

    useEffect(() => {
        const handleEvent = (res) => {
            if (res.status === 'paid') {
                participate()
            }
        };

        window.Telegram.WebApp.onEvent('invoiceClosed', handleEvent);

        // Cleanup the event listener on unmount
        return () => {
            window.Telegram.WebApp.offEvent('invoiceClosed', handleEvent);
        };
    }, [raffleTicketNumber, increaseChanceCoins, paymentType, telegramInvoiceLink]);

    return (
        <Drawer
            isOpen={isOpen}
            onClose={modalClose}
        >
            <div className={"d-flex p-3 flex-column gap-3 no-select"}>
                <div className={"text-center"}>
                    <LargeTitle>Lotty #{selectedRaffle?.id}</LargeTitle>
                </div>
                <div className={"d-flex flex-row text-center mb-1 justify-content-center align-items-center gap-1"}>
                    <OptimizedLottie
                        src={chuvak}
                        loop
                        autoplay
                        style={{
                            height: 20,
                            width: 20,
                        }}
                    />
                    <span className={"ton_num"}>
                    {formatNumber(selectedRaffle?.current_participants)} / {formatNumber(selectedRaffle?.max_participants)}
                    </span>
                </div>
                <Card className={"p-3"}>
                    <div className={"d-flex flex-column w-100"}>
                        <div className={"text-center"}>
                            <div className={""}>
                                <Headline className={"subtitle-color"}>Choose your lucky number</Headline>
                            </div>

                            <div className={"d-flex gap-2 align-items-center justify-content-center position-relative ticket-btns"}>
                                <IconButton
                                    onClick={decrementTicketNumber} // Обычный клик с проверкой уменьшения
                                    className={"position-absolute start-0"}
                                    size={"l"}
                                    mode={"gray"}
                                    onMouseDown={() => startAction(decrementTicketNumber)} // Запуск при зажатии
                                    onMouseUp={stopAction} // Остановка при отпускании
                                    onMouseLeave={stopAction} // Остановка при уходе курсора
                                    onTouchStart={() => startAction(decrementTicketNumber)} // Запуск на мобильных устройствах
                                    onTouchEnd={stopAction} // Остановка на мобильных
                                >
                                    -
                                </IconButton>

                                <IconButton
                                    onClick={incrementTicketNumber} // Обычный клик с проверкой увеличения
                                    className={"position-absolute end-0"} // Измените позицию по необходимости
                                    size={"l"}
                                    mode={"gray"}
                                    onMouseDown={() => startAction(incrementTicketNumber)} // Запуск при зажатии
                                    onMouseUp={stopAction} // Остановка при отпускании
                                    onMouseLeave={stopAction} // Остановка при уходе курсора
                                    onTouchStart={() => startAction(incrementTicketNumber)} // Запуск на мобильных устройствах
                                    onTouchEnd={stopAction} // Остановка на мобильных
                                >
                                    +
                                </IconButton>

                                <LargeTitle

                                    className={`py-3 ${checkIfNumberHasBeenTaken() ? 'text-red' : 'text-green'}`}
                                    style={{
                                        fontFamily: "Chakra Petch",
                                        fontSize: 58,
                                        lineHeight: "inherit!important",
                                        transition: '0.2s color'
                                    }}
                                    weight={3}>
                                    {raffleTicketNumber}
                                </LargeTitle>

                            </div>

                        </div>
                        <div className={"position-relative"}>
                            <div className={"d-block lotty-ticket-info"}>
                                <span className={"position-absolute start-20"}>1</span>
                                <span className={"position-absolute end-15"}>{formatNumber(selectedRaffle.max_participants)}</span>
                            </div>

                            <div className={"header-bg wrapper-slider"}>
                                <Slider
                                    className={`slider-no-bg ticket-number-slider ${checkIfNumberHasBeenTaken() ? 'red-btn' : 'green-btn'}`}
                                    onChange={(value) => handleSelectNumber(value)}
                                    value={raffleTicketNumber}
                                    max={selectedRaffle.max_participants}
                                    min={1}
                                />
                            </div>
                        </div>

                    </div>
                </Card>
                <IncreaseChance />
                <div className={"d-block w-100"}>
                    <Button
                        onClick={participateRaffle}
                        loading={loading || telegramStarsLoading}
                        size={"l"}
                        before={
                            <DotLottieReact
                                src={pin}
                                loop
                                autoplay
                                style={{
                                    height: 20,
                                    width: 20
                                }}
                            />
                        }
                        stretched
                    >
                        Confirm
                    </Button>
                </div>
            </div>

        </Drawer>
    )
}

export default ModalSelectNumber