import React, {useEffect, useState} from "react";
import {Badge, Button, Caption, Card, Headline, IconButton, Progress, Skeleton, Subheadline, Text} from "@telegram-apps/telegram-ui";
import Prizes from "./Parts/Prizes";
import chuvak from "../../../assets/lottie/dotlottie/chuvak.lottie";
import brilliant from "../../../assets/lottie/dotlottie/brilliant.lottie";
import open from "../../../assets/lottie/dotlottie/open.lottie";
import finished from "../../../assets/lottie/dotlottie/finished.lottie";
import new_status from "../../../assets/lottie/dotlottie/new.lottie";
import {formatNumber} from "../../../helpers/format_helper";
import {setSelectedRaffle} from "../../../slices/raffles/reducer";
import {useDispatch, useSelector} from "react-redux";
import {checkIsNew} from "../../../helpers/raffle_helper";
import CountUp from "react-countup";
import OptimizedLottie from "../OptimizedLottie";
import MemoizedAvatar from "../Profile/MemoizedAvatar";
import {FaCheck, FaPlay} from "react-icons/fa6";


const NewVerticalCard = (props) => {
    const {current} = props
    const dispatch = useDispatch()
    const setSelected = (el) => {
        dispatch(setSelectedRaffle(el))
    }

    const {raffles, loading} = useSelector((state) => state.Raffles);
    const raffle = raffles.find((r) => r.id === current?.id);

    const [prevCount, setPrevCount] = useState(raffle?.current_participants);
    const [percentage, setPercentage] = useState(0);

    useEffect(() => {
        if (raffle?.current_participants !== prevCount) {
            setPrevCount(raffle?.current_participants);
        }
    }, [raffle?.current_participants]);

    useEffect(() => {
        if (raffle?.current_participants) {
            calculatePercentage(raffle)
        }
    }, [raffle]);

    const calculatePercentage = (raffle) => {
        setPercentage(raffle.current_participants * raffle.max_participants / 100)
    }

    const key = Math.floor(Math.random() * 4) + 1;

    const colors = [
        '#595904', '#69275e', '#28568a', '#1c330c', '#69288a', '#8a2828', '#288a80'
    ]

    return (
        <Card
            onClick={() => {setSelected(current)}}
            style={{
                backgroundColor: colors[Math.floor(Math.random() * colors.length)],
                borderRadius: 8
            }}
            role={"button"}
            className={"d-flex flex-row gap-3 p-2 align-items-center lotty-item"}>
            {/*<div className={"prize-pool"}>*/}
            {/*    <Skeleton visible={loading}>*/}
            {/*        <div*/}
            {/*            style={{*/}
            {/*                width: 80,*/}
            {/*                height: 80*/}
            {/*            }}*/}
            {/*            className={"d-flex flex-column align-items-center justify-content-center subtitle-color"}*/}
            {/*        >*/}
            {/*            <Caption*/}
            {/*                style={{*/}
            {/*                    fontFamily: "Chakra Petch",*/}
            {/*                }}*/}
            {/*            >Lotty #</Caption>*/}
            {/*            <Subheadline weight={1}*/}
            {/*                style={{*/}
            {/*                    fontFamily: "Chakra Petch",*/}
            {/*                }}*/}
            {/*            >{current?.id}</Subheadline>*/}
            {/*        </div>*/}
            {/*    </Skeleton>*/}
            {/*</div>*/}
            <div className={"d-flex gap-1 flex-column w-100 "}>
                <div className={"d-flex flex-column gap-1"}>
                    <div className={"lotty-icon justify-content-center d-flex py-2"}>
                        <MemoizedAvatar size={100} src={`/images/lotty-images/lotty-${key}.jpg`} />
                    </div>
                    <div className={"d-block text-center"}>
                        <Subheadline weight={1}>Lotty #{current?.id}</Subheadline>
                    </div>
                    <div className={"block w-25 mx-auto justify-content-center"}>
                        <Skeleton visible={loading}>
                            <Progress className={"progress-lotty"} value={percentage} />
                        </Skeleton>
                    </div>
                </div>
                <div className={"d-flex justify-content-center pb-1 ton_num"}>
                        <Skeleton visible={loading}>
                            <div className={"d-flex flex-row text-center align-items-center gap-1 fw-200"}>
                                <OptimizedLottie
                                    src={chuvak}
                                    autoplay
                                    style={{
                                        height: 15,
                                        width: 15,
                                    }}
                                />
                                <Caption
                                    className={"ton_num"}
                                >
                                    <CountUp
                                        start={prevCount}
                                        end={current?.current_participants}
                                        duration={0.8} // Длительность анимации
                                        onEnd={() => setPrevCount(raffle?.current_participants)} // Обновляем после завершения
                                    />
                                </Caption>
                                <Caption>of</Caption>
                                <Caption
                                    className={"ton_num"}
                                >
                                    {formatNumber(current?.max_participants)}
                                </Caption>
                            </div>
                        </Skeleton>
                    </div>


                <div className={"footer-card"}>
                    <Button
                        before={
                            current?.is_participating ? <FaCheck size={16} /> : <FaPlay size={16} />
                            // <OptimizedLottie
                            //     key={current?.is_participating ? 'participating' : 'notParticipating'}
                            //     src={current?.is_participating === true  ? finished : open}
                            //     loop
                            //     autoplay
                            //     style={{
                            //         height: 20,
                            //         width: 20,
                            //     }}
                            // />

                        }
                        mode={"gray"}
                        size={'m'}
                        style={{
                            height: 'inherit'
                        }}
                        stretched
                        onClick={() => {setSelected(current)}}
                    >
                        <Caption weight={2}>
                            {current?.is_participating ?  'Joined' : 'Play'}
                        </Caption>

                    </Button>
                </div>

                <div className={"d-flex justify-content-center align-items-center py-1"}>
                    <div
                        className={"d-flex flex-row gap-2 align-items-center"}
                    >
                        <Prizes skeleton={loading} prizes={current?.prizes} />
                    </div>
                </div>
            </div>
        </Card>
    )
}

export default NewVerticalCard