import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useSocket } from "../../context/SocketProvider";
import { pushRaffle, updateGeneralRaffleInfo, updatePrivateRaffleInfo } from "../../slices/raffles/thunk";
import {pushTask, updateTasks} from "../../slices/tasks/thunk";
import { updateCounters } from "../../slices/app/thunk";
import { setShowWinnerModal, setWinnerData } from "../../slices/raffles/reducer";

const SocketEventListener = () => {
    const dispatch = useDispatch();
    const { socket, isConnected } = useSocket(); // Используем состояние подключения
    const { raffles, topRaffles } = useSelector(state => state.Raffles);
    const { tasks, dailyTasks } = useSelector(state => state.Task);
    const { counters } = useSelector(state => state.App);

    const handleUpdateCounter = useCallback((data) => {
        console.log('Counter updated:', data);
        dispatch(updateCounters(data, counters));
    }, [counters]);

    const handleUpdateTask = useCallback((data) => {
        console.log('Task updated:', data);
        dispatch(updateTasks(data, tasks));
    }, [tasks]);

    const handleGeneralUpdate = useCallback((data) => {
        console.log('General Raffle updated:', data);
        dispatch(updateGeneralRaffleInfo(data, raffles, topRaffles));
    }, [dispatch, raffles, topRaffles]);

    const handlePrivateUpdate = useCallback((data) => {
        console.log('Private Raffle updated:', data);
        dispatch(updatePrivateRaffleInfo(data, raffles, topRaffles));
    }, [dispatch, raffles, topRaffles]);

    const handleLottyFinished = useCallback((data) => {
        console.log('Lotty finished update:', data);
        dispatch(setShowWinnerModal(true));
        dispatch(setWinnerData(data));
    }, [dispatch]);

    const handleLottyCreated = useCallback((data) => {
        console.log('Lotty created update:', data);
        dispatch(pushRaffle(data, raffles, topRaffles));
    }, [dispatch, raffles, topRaffles]);

    const handleTaskCreated = useCallback((data) => {
        console.log('Task created update:', data);
        dispatch(pushTask(data, tasks, dailyTasks))
    }, [dispatch, tasks, dailyTasks]);

    useEffect(() => {
        if (!socket || !isConnected) return; // Ждем подключения

        console.log("Socket connected:", socket.id);

        // Подписываемся на события
        socket.on('counter_updated', handleUpdateCounter);
        socket.on('task_updated', handleUpdateTask);
        socket.on('general_raffle_updated', handleGeneralUpdate);
        socket.on('private_raffle_updated', handlePrivateUpdate);
        socket.on('raffle_finished', handleLottyFinished);
        socket.on('raffle_created', handleLottyCreated);
        socket.on('task_created', handleTaskCreated);

        // Очистка эффекта
        return () => {
            socket.off('counter_updated', handleUpdateCounter);
            socket.off('task_updated', handleUpdateTask);
            socket.off('general_raffle_updated', handleGeneralUpdate);
            socket.off('private_raffle_updated', handlePrivateUpdate);
            socket.off('raffle_finished', handleLottyFinished);
            socket.off('raffle_created', handleLottyCreated);
            socket.off('task_created', handleTaskCreated);
        };
    }, [socket,
        isConnected,
        handleUpdateCounter,
        handleUpdateTask,
        handleGeneralUpdate,
        handlePrivateUpdate,
        handleLottyFinished,
        handleLottyCreated,
        handleTaskCreated
    ]);

    return null;
};

export default SocketEventListener;