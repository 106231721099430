import {app} from '../config'
import {createLogApi} from "./real_api_helper";

export const subscribeTelegram = (id) => {
    window.Telegram.WebApp.openTelegramLink('https://t.me/'+id)
}

export const getInviteLink = (user) => {
    if (user) {
        return `https://t.me/${app.APP_NAME}/start?startapp=${user.id}`;
    }
}

export const inviteFriends = (user) => {
    const url = getInviteLink(user)
    const text = 'Welcome to Lotty! Invite friends and loot TON'
    window.Telegram.WebApp.openTelegramLink(`https://t.me/share/url?url=${encodeURIComponent(url)}&text=${encodeURIComponent(text)}`)
}

export const openTelegramLink = (link) => {
    window.Telegram.WebApp.openTelegramLink(link)
    window.Telegram.WebApp.HapticFeedback.impactOccurred('soft')
}

export const openLink = (link) => {
    let data = {
        type : 'open_link',
        data : {'link' : link}
    }
    createLogApi(data)
    window.Telegram.WebApp.openLink(link)
}

export const openUser = (user) => {
    if (user?.username)
        openTelegramLink(`https://t.me/${user.username}`)
}

export const openTgUser = (user) => {
    if (user?.username)
        return (
            <a
                style={{
                    color: "inherit!important"
                }}
                target={"_blank"}
                href={`https://t.me/${user.username}`}
                className={"link-color"}
                role={"button"}>@{user?.username}</a>
        )
    else
        return (
            user?.name
        )
}