import Drawer from "../Drawer";
import {Button, Caption, Card, Headline, Title} from "@telegram-apps/telegram-ui";
import MyCountdown from "../MyCountdown";
import React, {useEffect, useState} from "react";
import {Divider} from "@mui/material";
import {DotLottieReact} from "@lottiefiles/dotlottie-react";
import free from "../../../assets/lottie/dotlottie/fire.lottie";
import IconLottyStar from "../../icons/IconLottyStar";
import IconTelegramStar from "../../icons/IconTelegramStar";
import {payWithTelegramStars} from "../../../slices/app/thunk";
import {useDispatch, useSelector} from "react-redux";
import ModalConfirm from "../../../pages/Profile/ModalConfirm";
import ModalRaffleTasks from "../Raffle/Modals/ModalRaffleTasks";
import {toast} from "react-toastify";
import ModalGameTasks from "./ModalGameTasks";
import {checkTaskApi} from "../../../helpers/real_api_helper";
import OptimizedLottie from "../OptimizedLottie";
import {getGames} from "../../../slices/games/thunk";

const ModalGame = (props) => {
    const {isOpen, modalClose, nextAvailableAt, selectedGame, onPaySuccess} = props
    const {user} = useSelector(state => state.Auth)
    const {telegramStarsLoading} = useSelector(state => state.App)
    const {balance} = useSelector(state => state.Balance)
    const [modalConfirmOpen, setModalConfirmOpen] = useState(false);
    const [showModalTasks, setShowModalTasks] = useState(false)
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)

    const payWithSilverStars = async () => {
        if (balance?.star_balance >= 100) {
            await modalClose()
            await onPaySuccess('silver_stars')
        }
        else {
            toast.warning('Insufficient funds')
        }
        setModalConfirmOpen(false)
    }

    const closeModalTasks = () => {
        setShowModalTasks(false)
    }
    const sendTelegramInvoice = (type) => {
        let payload = JSON.stringify({
            id: selectedGame.id,
            user_id: user.id
        })

        dispatch(payWithTelegramStars(type, payload))
    }

    const checkCompletion =  () => {
        setLoading(true)
        checkTaskApi(selectedGame?.task)
            .then(async res => {
                await modalClose()
                await closeModalTasks()
                await onPaySuccess('task')
            })
            .catch(err => {
                const formattedMessage = (
                    <ul>
                        {err.data.message.split("\n").map((el, i) => (
                            <li key={i}>{el}</li>
                        ))}
                    </ul>
                )
                toast.warning(formattedMessage)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const payWithYellowStars = (url) => {
        modalClose()
        onPaySuccess('telegram_stars', url)
    }

    useEffect(() => {
        const handleEvent = (res) => {
            if (res.status === 'paid') {
                payWithYellowStars(res.url)
            }
        };

        window.Telegram.WebApp.onEvent('invoiceClosed', handleEvent);

        // Cleanup the event listener on unmount
        return () => {
            window.Telegram.WebApp.offEvent('invoiceClosed', handleEvent);
        };
    }, []);

    const onComplete = () => {
        dispatch(getGames())
    }
    return (
        <Drawer
            isOpen={isOpen}
            onClose={modalClose}
        >
            <ModalConfirm
                isOpen={modalConfirmOpen}
                modalClose={() => setModalConfirmOpen(false)}
                onConfirm={() => payWithSilverStars()}
            />
            <ModalGameTasks
                loading={loading}
                isOpen={showModalTasks}
                modalClose={closeModalTasks}
                selectedGame={selectedGame}
                checkCompletion={checkCompletion}
            />
            <div className={"d-flex w-100 flex-column gap-3 p-3 justify-content-center align-items-center"}>
                <Title
                    level="1"
                    weight="2"
                >
                    No more attempts!
                </Title>
                <Headline>
                    The next available attempt will be in
                </Headline>
                <MyCountdown onComplete={onComplete} date={Date.parse(nextAvailableAt)} />
                <Caption weight={2}>OR</Caption>
                <Card
                    className={"w-100 p-3"}
                >
                    <div className={"d-flex flex-column gap-2 w-100 text-center"}>
                        <Button
                            mode={'white'}
                            before={
                                <OptimizedLottie
                                    src={free}
                                    loop
                                    autoplay
                                    style={{
                                        height: 20,
                                        width: 20,
                                    }}
                                />
                            }
                            loading={loading}
                            onClick={() => {
                                setShowModalTasks(true)
                            }}
                        >
                            Free Try
                        </Button>
                        <Caption className={"subtitle-color"} weight={1} style={{
                        }}>OR</Caption>
                        <div className={"d-flex gap-2"}>
                            <Button
                                mode={'gray'}
                                onClick={() => setModalConfirmOpen(true)}
                                before={
                                    <IconLottyStar
                                        style={{
                                            height: 15,
                                            width: 15
                                        }}
                                    />
                                }
                                className={"w-100"}
                            >
                                Pay 200
                            </Button>
                            <Button
                                mode={'gray'}
                                style={{
                                    color: '#fdb345'
                                }}
                                loading={telegramStarsLoading}
                                onClick={() => sendTelegramInvoice(selectedGame?.type)}
                                before={
                                    <IconTelegramStar
                                        style={{
                                            height: 20,
                                            width: 20
                                        }}
                                    />
                                }
                                className={"w-100"}
                            >
                                Pay 5
                            </Button>
                        </div>
                    </div>
                </Card>
            </div>
        </Drawer>
    )
}
export default ModalGame