import {Image, Subheadline} from "@telegram-apps/telegram-ui";
import {getPrize} from "../../../helpers/raffle_helper";
import React from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setGame} from "../../../slices/games/reducer";

const Game = (props) => {
    const {current} = props
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const getGameIcon = (type) => {
        switch(type) {
            case 'roulette':
                return 'images/roulette.svg'
            default:
                return ''
        }
    }

    const playGame = (game) => {
        dispatch(setGame(game))
        switch (game.type) {
            case 'roulette':
                return navigate('/roulette')
            default:
                return ;
        }
    }

    return (
        <div
            onClick={() => playGame(current)}
            className={`task d-flex flex-row justify-content-between align-items-center`}
            role={'button'}
        >
            <div className={"d-flex flex-row gap-3 align-items-center w-75"}>
                <div className={"task-icon"}>
                    <Image size={65} src={getGameIcon(current?.type)} />
                </div>

                <div className={"d-flex flex-column"}>
                    <Subheadline weight={2}>
                        {current?.name}
                    </Subheadline>
                </div>
            </div>
            {getPrize(current.reward_type, current?.max_reward, true)}
        </div>
    )
}

export default Game