const IconLottyCoin = (props) => {
    return (
        <svg {...props} viewBox="0 0 857 756" fill="none">
            <path d="M584.596 348.906C538.686 223.422 461.487 22.7488 461.487 22.7488C449.87 -7.58293 407.135 -7.58293 395.513 22.7488C395.513 22.7488 318.314 223.422 269.343 348.906C245.893 408.993 205.068 367.269 205.068 367.269L56.6546 251.031C29.2096 230.36 -8.51675 257.128 1.71616 290.01L34.4051 373.314C47.9466 405.261 55.452 442.182 85.7 462.147C96.1332 469.033 112.599 473.073 140.748 467.427C156.387 464.29 340.255 409.945 385.65 394.814C440.743 376.451 437.682 416.29 407.075 432.919C315.661 482.583 187.259 507.225 153.036 621.297C141.424 660 171.4 755.962 232.614 755.962C293.829 755.962 326.552 706.993 428.5 706.993C473.758 706.993 547.868 741.82 557.05 746.781C566.232 751.742 645.811 777.386 688.661 713.114C724.354 659.576 855.284 290.01 855.284 290.01C865.517 257.128 827.79 230.36 800.345 251.031L648.871 367.269C648.871 367.269 606.021 407.465 584.596 348.906Z" fill="#67a741"/>
            <defs>
                <linearGradient id="paint0_linear_1272_42" x1="428.5" y1="0" x2="428.5" y2="756" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#7EFD97"/>
                    <stop offset="1" stopColor="#2A893D"/>
                </linearGradient>
            </defs>
        </svg>


    )
}

export default IconLottyCoin