import {Button, Caption, Card, Headline, Title} from "@telegram-apps/telegram-ui";
import React from 'react'
import WalletList from "./WalletList";
import TransactionsList from "./TransactionsList";
import {useNavigate} from "react-router-dom";
import {DotLottieReact} from "@lottiefiles/dotlottie-react";
import wallet from "../../assets/lottie/dotlottie/wallet.lottie";
import {IoSwapVertical, IoWalletOutline} from "react-icons/io5";
import {PiHandWithdraw} from "react-icons/pi";
import {useTonConnectModal} from "@tonconnect/ui-react";
import ConnectWalletButton from "../../Components/custom/ConnectWalletButton/ConnectWalletButton";
import OptimizedLottie from "../../Components/custom/OptimizedLottie";

const Profile = () => {


    const navigate = useNavigate()


    return (
        <div className={"p-2"}>
            <div
                className={"d-flex justify-content-center flex-column align-items-center text-center mb-2"}>
                <OptimizedLottie
                    style={{
                        height: 100,
                        width: 100
                    }}
                    src={wallet}
                    loop
                    autoplay
                />
                <Title
                    weight={3}
                >
                    Wallet
                </Title>
                <div className={"d-flex py-2 gap-2 justify-content-center align-items-center wallet-btns"}>
                    <ConnectWalletButton />
                    <Button
                        className={"d-block b-8"}
                        before={
                            <div className={"py-1"}>
                                <PiHandWithdraw  size={20}/>
                            </div>
                        }
                        onClick={() => {
                            navigate('/withdraw')
                        }}
                        mode={"gray"}
                        size={"s"}>
                        Withdraw
                    </Button>
                    <Button
                        mode={"gray"}
                        className={"d-block b-8"}
                        before={
                            <div className={"py-1"}>
                                <IoSwapVertical size={20} />
                            </div>
                        }
                        onClick={() => {
                            navigate('/converter')
                        }}
                        size={"s"}
                    >
                        Converter
                    </Button>
                </div>
            </div>


            <WalletList />
            <div className={"d-flex gap-2 w-100 mt-2 text-center justify-content-center"}>
                <Caption weight={3}>
                    You will be able to convert your Lotty coins
                    to $LTY token later. <a target={"_blank"} href={'https://lotty.tg/coins'} className={"tg-link"}>Learn more</a>
                </Caption>
            </div>
            <TransactionsList />

        </div>
    )
}

export default Profile