import React, { useRef } from 'react';
import {DotLottieReact} from "@lottiefiles/dotlottie-react";
import { useInView } from 'react-intersection-observer';

const OptimizedLottie = (props) => {
    const { ref, inView } = useInView({
        threshold: 0.5,
        triggerOnce: true
    });

    return (
        <div ref={ref}>
            {inView && (
                <DotLottieReact
                    {...props}
                    autoplay={inView}
                    speed={0.6}
                />
            )}
        </div>
    );
};

export default React.memo(OptimizedLottie);