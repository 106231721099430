import React from 'react'
import RaffleList from "../../Components/custom/RaffleList";
import TopWinners from "./TopWinners";
const Home = () => {
    return (
        <React.Fragment>
            <TopWinners />
            <RaffleList />

        </React.Fragment>
    )
}
export default Home