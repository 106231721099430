import {setFriendMeta, setFriends, setLoading, setShowSkeleton} from "./reducer";
import {getFriendsApi} from "../../helpers/real_api_helper";

export const getFriends = (page = 1, append = false, showSkeleton = false) => async (dispatch) => {
    try {
        if (showSkeleton)
            dispatch(setShowSkeleton(true))
        dispatch(setLoading(true))
        getFriendsApi(page)
            .then(res => {
                dispatch(setFriends({
                    data: res.data,
                    append
                }))
                dispatch(setFriendMeta(res.meta))
            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => {
                if (showSkeleton)
                    dispatch(setShowSkeleton(false))
                dispatch(setLoading(false))
            })
    }
    catch (error) {

    }
}