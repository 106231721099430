import giftLottie from "../../../assets/lottie/gift.json";
import Lottie from "lottie-react";
import React from "react";
import {Button, Card, Skeleton, Subheadline, Text} from "@telegram-apps/telegram-ui";
import Prizes from "./Parts/Prizes";
import chuvak from "../../../assets/lottie/dotlottie/chuvak.lottie";
import {formatNumber} from "../../../helpers/format_helper";
import {setSelectedRaffle} from "../../../slices/raffles/reducer";
import {useDispatch, useSelector} from "react-redux";
import first from "../../../assets/lottie/dotlottie/1st.lottie";
import second from "../../../assets/lottie/dotlottie/2nd.lottie";
import third from "../../../assets/lottie/dotlottie/3rd.lottie";
import {getPrize} from "../../../helpers/raffle_helper";
import party from "../../../assets/lottie/dotlottie/party.lottie";
import {openTgUser} from "../../../helpers/telegram_app_helper";
import OptimizedLottie from "../OptimizedLottie";

const HorizontalFinishedLotteryCard = (props) => {
    const {current} = props
    const dispatch = useDispatch()

    const {loading} = useSelector((state) => state.Raffles);
    const setSelected = (el) => {
        dispatch(setSelectedRaffle(el))
    }

    const getFirstWinners = () => {
        const winners = [];
        const matrix = current.matrix
        const currentWinners = current.winners

        // Функция для проверки, входит ли место в диапазон
        const isInRange = (place, range) => {
            const [start, end] = range.split('-').map(Number);
            return place >= start && place <= end;
        };

        // Получаем первого победителя для каждой из первых трех групп fiatPrizes
        matrix.forEach(prize => {
            if (winners.length < 3) { // Если еще не нашли 3 победителя
                const firstWinner = currentWinners.find(winner =>
                    isInRange(winner.place, prize.range)
                );
                if (firstWinner) {
                    winners.push(firstWinner);
                }
            }
        });

        if (!winners.length) {
           return [
               {prize: {type: 'ton'}, amount: 0.13, user: {username: 'test'}},
               {prize: {type: 'ton'}, amount: 0.13, user: {username: 'test'}},
               {prize: {type: 'ton'}, amount: 0.13, user: {username: 'test'}}
            ]
        }

        return winners ; // Возвращаем найденных победителей
    };

    return (
        <Card
            style={{
                borderRadius: 8
            }}
            className={"d-flex flex-column p-3 justify-content-between"}>
            <div className={"d-flex"}>
                <div className={"d-flex gap-1"}>
                    <Skeleton visible={loading}>
                        <div className={"lotty-icon"}>
                            <Lottie
                                style={{
                                    width: 80,
                                    height: 60
                                }}
                                animationData={giftLottie} loop={false}
                            />
                        </div>
                    </Skeleton>
                    <div className={"d-flex flex-column gap-1"}>
                        <Skeleton visible={loading}>
                            <Subheadline weight={2}>Lotty #{current.id}</Subheadline>
                        </Skeleton>
                        <div className={"d-flex flex-row gap-3"}>
                            <Prizes skeleton={loading} prizes={current.prizes} />
                        </div>
                        <Skeleton visible={loading}>
                            <div className={"d-flex flex-row text-center align-items-center gap-1"}>
                                <OptimizedLottie
                                    src={chuvak}
                                    loop
                                    autoplay
                                    style={{
                                        height: 15,
                                        width: 15,
                                    }}
                                />
                                <span
                                    className={"ton_num"}
                                >
                            {formatNumber(current?.max_participants)}
                        </span>

                            </div>
                        </Skeleton>

                    </div>

                </div>
            </div>
            <div className={"d-flex flex-column pt-2 gap-2"}>
                <Skeleton visible={loading}>
                    <div className={"d-flex px-2 flex-column lotty-winners"}>
                        {getFirstWinners().map((el, i) => (
                            <div key={i} className={"d-flex align-items-center p-1 gap-1 justify-content-between"}>
                                <div className={"d-flex gap-1 align-items-center"}>
                                    <OptimizedLottie
                                        src={i === 0 ? first : i === 1 ? second : third}
                                        loop
                                        autoplay
                                        style={{
                                            height: 20,
                                            width: 20
                                        }}
                                    />
                                    <div className={"d-flex gap-1"}>
                                    <span className={"ton_num"}>
                                        {openTgUser(el?.user)}
                                    </span>
                                    </div>

                                </div>
                                <div className={"d-flex align-items-center gap-1"}>
                                    {getPrize(el.prize.type, el.amount)}
                                </div>

                            </div>
                        ))}

                    </div>
                </Skeleton>
                <Skeleton visible={loading}>
                    <div className={"card-button"}>
                        <Button
                            before={
                                <OptimizedLottie
                                    src={party}
                                    loop
                                    autoplay
                                    style={{
                                        height: 20,
                                        width: 20,
                                    }}
                                />
                            }
                            mode={"bezeled"}
                            size={'l'}
                            style={{
                                height: 'inherit'
                            }}
                            stretched
                            onClick={() => {setSelected(current)}}
                        >
                            <Text weight={2}>
                                Winner List
                            </Text>
                        </Button>
                    </div>
                </Skeleton>

            </div>
        </Card>
    )
}

export default HorizontalFinishedLotteryCard