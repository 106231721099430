import {Card, Headline, LargeTitle, Progress, Slider, Subheadline} from "@telegram-apps/telegram-ui";
import {formatNumber} from "../../helpers/format_helper";
import IconLottyCoin from "../icons/IconLottyCoin";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getBalance} from "../../slices/balance/thunk";
import {setIncreaseChanceCoins} from "../../slices/app/reducer";

const LevelRewards = (props) => {


    // const {remainingBalance, setRemainingBalance} = props
    const [currentSliderValue, setCurrentSliderValue] = useState(0)
    const {balance} = useSelector(state => state.Balance)
    const {increaseChanceCoins, resetChanceSlider} = useSelector(state => state.App)
    const [chance, setChance] = useState(1)
    const [remainingBalance, setRemainingBalance] = useState(0)
    const dispatch = useDispatch()



    return (
        <Card className={"p-3 w-100"}>
            <div className={"d-flex flex-column w-100"}>
                <Headline className={"d-flex gap-1 align-items-center justify-content-center subtitle-color text-center"}>
                    Increase your chance with Lotty Coins
                </Headline>
                <div className={"d-flex gap-2 align-items-center justify-content-center position-relative ticket-btns py-3"}>
                    <LargeTitle
                        style={{
                            fontFamily: "Chakra Petch",
                            fontSize: 58,
                            lineHeight: "inherit!important",
                            color: '#52c2f6',
                            transition: '0.2s all'
                        }}
                        weight={3}>
                        <sub
                            style={{
                                top: 0,
                                fontSize: "0.5em"
                            }}
                        >x</sub>
                        {chance}
                    </LargeTitle>

                </div>
                <div className={"header-bg wrapper-slider position-relative"}>
                    <div className={"lotty-progress-wrapper"}>
                        <Progress
                            className={"lotty-progress"}
                            value={87}
                        />
                        <small className={"current-percentage subtitle-color"}>
                            30,5% / 100%
                        </small>
                    </div>
                </div>
            </div>
        </Card>
    )
}

export default LevelRewards