import React, { useRef, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './Drawer.css';
import { Icon28Close } from '@telegram-apps/telegram-ui/dist/icons/28/close';

const Drawer = ({ isOpen, onClose, children, closeThreshold = 0.25, customClass = null }) => {
    const modalRef = useRef(null);
    const headerRef = useRef(null);
    const startY = useRef(0);
    const currentTranslateY = useRef(0);
    const isDragging = useRef(false);
    const [visible, setVisible] = useState(false);
    const [style, setStyle] = useState({
        transform: 'translate3d(0, 100%, 0)',
        transition: 'transform 0.6s cubic-bezier(0.25, 1, 0.5, 1)',
    });
    const [overlayOpacity, setOverlayOpacity] = useState(0);

    useEffect(() => {
        let openTimeout;

        if (isOpen) {
            setVisible(true);
            setStyle({
                transform: 'translate3d(0, 100%, 0)',
                transition: 'none',
            });

            openTimeout = setTimeout(() => {
                setStyle({
                    transform: 'translate3d(0, 0, 0)',
                    transition: 'transform 0.6s cubic-bezier(0.25, 1, 0.5, 1)',
                });
                setOverlayOpacity(1);
            }, 50);
        } else {
            setStyle({
                transform: 'translate3d(0, 100%, 0)',
                transition: 'transform 0.6s cubic-bezier(0.25, 1, 0.5, 1)',
            });
            setOverlayOpacity(0);
            setTimeout(() => setVisible(false), 100);
        }

        return () => clearTimeout(openTimeout);
    }, [isOpen]);

    const updatePosition = (translateY) => {
        if (modalRef.current) {
            const constrainedTranslateY = Math.max(translateY, 0);
            modalRef.current.style.transform = `translate3d(0, ${constrainedTranslateY.toFixed(4)}px, 0)`;
            setOverlayOpacity(1 - constrainedTranslateY / modalRef.current.offsetHeight);
        }
    };

    const handleTouchStart = (e) => {
        if (!headerRef.current.contains(e.target)) return; // Проверяем, что тянем за заголовок
        startY.current = e.touches[0].clientY;
        isDragging.current = true;
        setStyle({ ...style, transition: 'none' });
    };

    const handleTouchMove = (e) => {
        if (!isDragging.current) return;

        const touchY = e.touches[0].clientY;
        const translateY = touchY - startY.current;

        if (currentTranslateY.current === 0 && translateY < 0) return; // Не тянуть вверх, если модалка полностью открыта

        currentTranslateY.current = translateY;

        // Обновляем позицию модалки только если тянем вниз
        if (translateY > 0) {
            // e.preventDefault(); // Запретить прокрутку страницы
            requestAnimationFrame(() => updatePosition(translateY));
        }
    };

    const handleTouchEnd = () => {
        if (!isDragging.current) return;

        isDragging.current = false;

        if (currentTranslateY.current > modalRef.current.offsetHeight * closeThreshold) {
            setStyle({ pointerEvents: 'auto', transition: 'transform 0.6s cubic-bezier(0.25, 1, 0.5, 1)' });
            requestAnimationFrame(() => updatePosition(modalRef.current.offsetHeight));
            setOverlayOpacity(0);
            setTimeout(onClose, 100);
        } else {
            setStyle({ pointerEvents: 'auto', transition: 'transform 0.6s cubic-bezier(0.25, 1, 0.5, 1)' });
            requestAnimationFrame(() => updatePosition(0));
            setOverlayOpacity(1);
        }
    };

    // Проверяем, есть ли элемент <AppRoot> в DOM
    const appRoot = document.getElementById('app-root');
    if (!appRoot) {
        console.error('AppRoot элемент не найден в DOM');
        return null;
    }

    const drawerContent = (
        <>
            {visible && (
                <>
                    {isOpen && (
                        <div
                            className="overlay"
                            onClick={onClose}
                            style={{
                                background: 'rgba(33, 33, 33, 0.4)',
                                opacity: overlayOpacity.toFixed(6),
                                pointerEvents: 'auto',
                                transition: 'opacity 0.6s cubic-bezier(0.25, 1, 0.5, 1)',
                            }}
                        ></div>
                    )}
                    <div
                        ref={modalRef}
                        className={`drawer ${isOpen ? 'open' : ''} ${customClass}`}
                        style={style}
                        onTouchStart={handleTouchStart}
                        onTouchMove={handleTouchMove}
                        onTouchEnd={handleTouchEnd}
                    >
                        <div ref={headerRef} className={"drawer-header d-flex justify-content-end"}>
                            <Icon28Close
                                onClick={onClose}
                                role={"button"}
                                style={{ color: 'var(--tgui--plain_foreground)' }}
                            />
                        </div>
                        <div className="drawer-content" style={{ overflowY: 'auto', maxHeight: '80vh' }}>
                            {children}
                        </div>
                    </div>
                </>
            )}
        </>
    );

    // Рендерим Drawer в AppRoot с помощью портала
    return ReactDOM.createPortal(drawerContent, appRoot);
};

export default Drawer;