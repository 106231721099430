import {getNotificationsApi, getTopUsersApi} from "../../helpers/real_api_helper";
import {setCurrentUser, setTopUsers} from "./reducer";
import {setShowWinnerModal, setWinnerData} from "../raffles/reducer";
export const getTopUsers = () => async (dispatch) => {
    try {
        const res = await getTopUsersApi();
        dispatch(setTopUsers(res.data));
        dispatch(setCurrentUser(res.currentUser));
    } catch (error) {
        console.error(error);
    }
}

export const getNotifications = () => async (dispatch) => {
    getNotificationsApi()
        .then(res => {
            if (res.data) {
                dispatch(setShowWinnerModal(true))
                let data = {
                    ...res.data.payload,
                    notification: res.data.id
                }
                dispatch(setWinnerData(data))
            }
        })
        .catch(err => {

        })
}