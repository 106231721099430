import {Button, Card, Skeleton, Subheadline, Text} from "@telegram-apps/telegram-ui";
import React from "react";
import IconTon from "../../icons/IconTon";
import IconLottyStar from "../../icons/IconLottyStar";

const RaffleSkeleton = () => {

    return (

        <Card
            style={{
                borderRadius: 8,
                height: 236
            }}
            className={"d-flex align-items-center flex-column gap-2 py-3 px-3 cursor-pointer w-100"}
            type={"plain"}
        >
            <Skeleton visible={true}>
            <Text
                weight={3}
            >
                <div className={"d-flex flex-column"}>
                    <div className={"d-flex gap-1"}>
                        <IconTon className={"ton"}/>
                        <span
                            className={"ton_num"}
                        >10</span>
                    </div>
                    <div className={"d-flex gap-1 align-items-center"}>
                        <IconLottyStar/>
                        <span
                            className={"ton_num"}
                        >500K</span>
                    </div>
                    <div className={"d-flex gap-1 align-items-center"}>
                        <IconLottyStar/>
                        <span
                            className={"ton_num"}
                        >500K</span>
                    </div>
                </div>
            </Text>
            </Skeleton>
            <Skeleton visible={true}>
            <div className={"d-flex flex-column text-center"}>
                <Subheadline weight={2}>
                    Participants
                </Subheadline>
                <span
                    className={"ton_num"}
                >
                     👥 100 / 1000
                </span>
            </div>
            </Skeleton>
            <Skeleton visible={true}>
                <div className={"mt-2"}>
                    <Button
                        mode={"bezeled"}
                        stretched
                    >
                        🏆 Show winners
                    </Button>
                </div>
            </Skeleton>

        </Card>
    )
}

export default RaffleSkeleton