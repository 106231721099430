function useTaskIcon(task) {
    // Определяем логику для выбора иконки
    const getIcon = () => {
        switch (task?.icon) {
            case 'youtube':
                return {name: "youtube", src: "/images/icons/youtube.svg"}
            case 'telegram':
                return {name: "telegram", src: "/images/icons/telegram.svg"}
            case 'the-x':
                return {name: "twitter", src: "/images/icons/twitter.svg"}
            case 'instagram':
                return {name: "twitter", src: "/images/icons/instagram.svg"}
            case 'facebook':
                return {name: "twitter", src: "/images/icons/facebook.svg"}
            case 'tik-tok':
                return {name: "twitter", src: "/images/icons/tiktok.svg"}
            default:
                return {name: "default",  src: "" };
        }
    };

    return getIcon();
}

export default useTaskIcon;