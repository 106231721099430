import {Button, Headline, Image, Modal, Subheadline, Title} from "@telegram-apps/telegram-ui";
import React, {useState} from "react";
import useTaskIcon from "../../hooks/useTaskIcon";
import {checkTaskApi} from "../../helpers/real_api_helper";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import Drawer from "./Drawer";
import {setReward, setShowConfetti} from "../../slices/app/reducer";
import {getCounters, showConfetti} from "../../slices/app/thunk";
import {getTasks} from "../../slices/tasks/thunk";
import {useTaskRow} from "../../hooks/useTaskRow";
import {DotLottieReact} from "@lottiefiles/dotlottie-react";
import unlock from "../../assets/lottie/dotlottie/unlock.lottie";
import {getPrize} from "../../helpers/raffle_helper";
import OptimizedLottie from "./OptimizedLottie";

const ModalTask = (props) => {
    const {modalClose, isOpen, task, headline} = props
    const dispatch = useDispatch()
    const {user} = useSelector(state => state.Auth)
    const [loading, setLoading] = useState(false)
    const {src} = useTaskIcon(task)
    const {getTaskRow} = useTaskRow()

    const checkTask = () => {
        dispatch(setShowConfetti(false))
        setLoading(true)
        checkTaskApi(task)
            .then(res => {
                modalClose()
                let reward = [{
                    reward_type: "coin",
                    amount: task.reward,
                    transaction_type: 'credit'
                }]
                dispatch(setReward(reward))
                dispatch(showConfetti())
                toast.success('Task successfully completed')
            })
            .catch(err => {
                toast.warning(err.data.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <Drawer
            isOpen={isOpen}
            onClose={modalClose}
        >
            <div className={"d-flex w-100 flex-column gap-3 p-3"}>
                <div className={"task-icon flex-column gap-3 align-items-center d-flex justify-content-center"}>
                    <Image src={src} size={90}></Image>
                    <Subheadline className={"d-flex gap-1"} level={2}>
                        {getPrize(task?.reward_type, task?.reward, true)} ️
                    </Subheadline>
                </div>
                <div className={"d-flex flex-column gap-3 align-items-center"}>
                    <Headline
                        className={"text-center"}
                        level="1"
                        weight="2"
                    >
                        {task?.name}
                    </Headline>
                    <Headline
                        weight={1}
                    >
                        {headline}
                    </Headline>
                </div>


                <div className={"requirements d-flex flex-column py-3 px-3 gap-3"}>
                    {task?.conditions && task?.conditions.map((el, i) => (
                        getTaskRow(el, i)
                    ))}
                </div>
                <div className={"d-flex flex-column w-100"}>
                    <Button
                        loading={loading}
                        onClick={() => checkTask()}
                        mode={"bezeled"}
                        size={"l"}
                        before={
                            <OptimizedLottie
                                src={unlock}
                                loop
                                autoplay
                                style={{
                                    height: 20,
                                    width: 20,
                                }}
                            />
                        }
                    >
                        Check task
                    </Button>
                </div>
            </div>
        </Drawer>
    )
}

export default ModalTask