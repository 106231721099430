import {
    setLoading,
    setRaffleMeta,
    setRaffles, setSelectedInnerType,
    setSelectedTab,
    setShowSkeleton,
    setTopRaffles,
    setTopRaffleSkeleton,
    setWinners,
    setWinnersMeta
} from "./reducer";
import {getRafflesApi, getRaffleWinnersApi, participateRaffleApi} from "../../helpers/real_api_helper";
import topRaffles from "../../pages/Home/TopRaffles";

export const getRaffles = (page = 1, type, innerType = 'all', append = false) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const res = await getRafflesApi(page, type, innerType);
        dispatch(setRaffles({
            data: res.data,
            append
        }));
        dispatch(setRaffleMeta(res.meta));
    } catch (err) {
        console.log(err);
    } finally {
        dispatch(setLoading(false));
        dispatch(setShowSkeleton(false));
    }
};

export const getTopRaffles = (page = 1, type, innerType = 'all') => async (dispatch) => {
    try {
        dispatch(setTopRaffleSkeleton(false))
        const res = await getRafflesApi(page, type, innerType, true);
        dispatch(setTopRaffles(res.data));
    } catch (err) {
        console.log(err);
    } finally {
    }
};

export const getRaffleWinners = (page = 1, raffle, append = false) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const res = await getRaffleWinnersApi(page, raffle);
        dispatch(setWinners({
            data: res.data,
            append
        }));
        dispatch(setWinnersMeta(res.meta));
    } catch (err) {
        console.log(err);
    } finally {
        dispatch(setLoading(false));
    }
};


export const participateToRaffle = (data, raffle) => async (dispatch) => {
    try {
        let res = await participateRaffleApi(raffle, data);
        // await dispatch(getTopRaffles(1, 'active'));
        // await dispatch(getRaffles(1, 'active', 'joined'));
        return res; // Возвращаем результат запроса
    } catch (err) {
        // Возвращаем ошибку для обработки в компоненте
        throw err;
    }
}

export const updateGeneralRaffleInfo = (data, raffles, topRaffles) => async (dispatch) => {
    const updatedRaffles = await raffles.map(raffle => {
        if (raffle.id === data.id) {
            return {
                ...raffle,
                current_participants: data.current_participants,
                is_closed: data.is_closed,
                took_numbers: data.took_numbers,
                // Обновляем только необходимые поля
            };
        }
        return raffle;
    });

    const updatedTopRaffles = await topRaffles.map(raffle => {
        if (raffle.id === data.id) {
            return {
                ...raffle,
                current_participants: data.current_participants,
                is_closed: data.is_closed,
                took_numbers: data.took_numbers,
                // Обновляем только необходимые поля
            };
        }
        return raffle;
    });

    dispatch(setTopRaffles(updatedTopRaffles));

    dispatch(setRaffles({
        data: updatedRaffles,
        append: false
    }));
}


export const updatePrivateRaffleInfo = (data, raffles, topRaffles) => async (dispatch) => {
    const updatedRaffles = await raffles.map(raffle => {
        if (raffle.id === data.id) {
            return {
                ...raffle,
                is_participating: data.is_participating,
                ticket_number: data.ticket_number,
                raffle_chance: data.raffle_chance,
                // Обновляем только необходимые поля
            };
        }
        return raffle;
    });


    const updatedTopRaffles = await topRaffles.map(raffle => {
        if (raffle.id === data.id) {
            return {
                ...raffle,
                is_participating: data.is_participating,
                ticket_number: data.ticket_number,
                raffle_chance: data.raffle_chance,
                // Обновляем только необходимые поля
            };
        }
        return raffle;
    });

    dispatch(setTopRaffles(updatedTopRaffles));
    dispatch(setRaffles({
        data: updatedRaffles,
        append: false
    }));
}

export const pushRaffle = (raffle, raffles, topRaffles) => async (dispatch) => {
    // Проверяем, существует ли розыгрыш с таким же id в raffles
    const isRaffleExists = raffles.some(existingRaffle => existingRaffle.id === raffle.id);

    if (!isRaffleExists) {
        let updatedRaffles = [...raffles];
        updatedRaffles.unshift(raffle); // Добавляем новый розыгрыш в начало
        dispatch(setRaffles({
            data: updatedRaffles,
            append: false
        }));
    }

    // Проверяем, существует ли розыгрыш с таким же id в topRaffles, если это топовый розыгрыш
    if (raffle.is_top) {
        const isTopRaffleExists = topRaffles.some(existingRaffle => existingRaffle.id === raffle.id);

        if (!isTopRaffleExists) {
            let updatedTopRaffles = [...topRaffles];
            updatedTopRaffles.unshift(raffle); // Добавляем новый топ-розыгрыш в начало
            dispatch(setTopRaffles(updatedTopRaffles));
        }
    }
}