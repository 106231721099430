import PrizeWheel from "../../Components/custom/Game/PrizeWheel";
import ReactDOM from "react-dom";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getGame} from "../../slices/games/thunk";

const Roulette = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {selectedGame} = useSelector(state => state.Games)

    useEffect(() => {
        if (!selectedGame) {
            dispatch(getGame(1))
        }
    }, [dispatch])

    useEffect(() => {
        const backButtonHandler = () => {
            navigate('/rewards')
            window.Telegram.WebApp.BackButton.hide()
        }

        window.Telegram.WebApp.BackButton.show()
        window.Telegram.WebApp.onEvent('backButtonClicked', backButtonHandler);
        return () => {
            window.Telegram.WebApp.offEvent('backButtonClicked', backButtonHandler);
        };
    }, [navigate]);

    const appRoot = document.getElementById('app-root');
    if (!appRoot) {
        console.error('AppRoot элемент не найден в DOM');
        return null;
    }
    const content = (
        <div
            style={{
                position:'fixed',
                zIndex: 1,
                overflowY: 'scroll',
                height: '100%'
            }}
            className={"d-flex align-items-center flex-column p-3 min-vh-100 tg-secondary-bg w-100"}
        >
            {selectedGame && <PrizeWheel />}
        </div>
    )

    return ReactDOM.createPortal(content, appRoot);
}

export default Roulette