import {getTasksApi} from "../../helpers/real_api_helper";
import {setDailyTasks, setShowSkeleton, setTasks} from "./reducer";
import {setRaffles, setTopRaffles} from "../raffles/reducer";


export const getTasks = () => async (dispatch) => {
    try {
        dispatch(setShowSkeleton(true))
        getTasksApi()
            .then(res => {
                dispatch(setTasks(res.data))
            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => {
                dispatch(setShowSkeleton(false))
            })
    }
    catch (error) {

    }
}

export const updateTasks = (task, tasks) => async (dispatch) => {
    const updatedTasks = tasks
        .map(el => {
            if (el.id === task.id) {
                return {
                    ...el,
                    ...task,
                };
            }
            return el;
        })
        // Сортируем так, чтобы задачи с `is_available === false` оказались в конце
        .sort((a, b) => (a.is_available === b.is_available ? 0 : a.is_available ? -1 : 1));

    dispatch(setTasks(updatedTasks));
};

export const pushTask = (task, tasks, dailyTasks) => async (dispatch) => {
    const isTaskExists = tasks.some(existingTask => existingTask.id === task.id);

    if (!isTaskExists) {
        let updatedTasks = [...tasks];
        updatedTasks.unshift(task); // Добавляем новый розыгрыш в начало
        dispatch(setTasks(updatedTasks));
    }

    if (task?.is_daily) {
        const isDailyTaskExists = dailyTasks.some(existingTask => existingTask.id === task.id);

        if (!isDailyTaskExists) {
            let updatedDailyTasks = [...dailyTasks];
            updatedDailyTasks.unshift(task); // Добавляем новый топ-розыгрыш в начало
            dispatch(setDailyTasks(updatedDailyTasks));
        }
    }
}

export const pushRaffle = (raffle, raffles, topRaffles) => async (dispatch) => {
    // Проверяем, существует ли розыгрыш с таким же id в raffles




    // Проверяем, существует ли розыгрыш с таким же id в topRaffles, если это топовый розыгрыш

}