import {Avatar, Button, Caption, Skeleton, Subheadline} from "@telegram-apps/telegram-ui";
import {formatNumber} from "../../helpers/format_helper";
import IconTon from "../../Components/icons/IconTon";
import {DotLottieReact} from "@lottiefiles/dotlottie-react";
import first from "../../assets/lottie/dotlottie/1st.lottie";
import second from "../../assets/lottie/dotlottie/2nd.lottie";
import third from "../../assets/lottie/dotlottie/3rd.lottie";
import topLottie from "../../assets/lottie/dotlottie/top.lottie";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {getTopUsers} from "../../slices/users/thunk";
import {openTgUser, openUser} from "../../helpers/telegram_app_helper";
import OptimizedLottie from "../../Components/custom/OptimizedLottie";
import MemoizedAvatar from "../../Components/custom/Profile/MemoizedAvatar";

const TopWinners = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {topUsers} = useSelector(state => state.Users)
    const [top, setTop] = useState([1,2,3])
    useEffect(() => {
        dispatch(getTopUsers())
    }, []);

    useEffect(() => {
        if (topUsers && topUsers.length) {
            let topLocal = [
                topUsers.find(el => el?.rank === 2),
                topUsers.find(el => el?.rank === 1),
                topUsers.find(el => el?.rank === 3),
            ]
            setTop(topLocal)
        }
    }, [topUsers])

    return (
        <div className={"main-bg p-3 d-flex justify-content-center flex-column gap-2"}
        >
            <div className={"d-flex gap-4 flex-row justify-content-center mb-2 main-bg"}>
                {top.length && top.map((el, i) => (
                    <Skeleton key={i} visible={!topUsers}>
                        <div>
                                <div
                                    role={"button"}
                                    className={"top-item d-flex flex-column text-center"}>
                                    <a
                                        className={"link-color"}
                                        target={"_blank"}
                                        href={`https://t.me/${el.username}`}>
                                    <div
                                        className={"item-img d-flex justify-content-center position-relative"}
                                    >
                                        <MemoizedAvatar
                                            style={{
                                                marginTop: el?.rank !== 1 ? '10px' : ''
                                            }}
                                            size={
                                                el?.rank === 1 ? 70 : el?.rank === 2 ? 50 : 50
                                            }
                                            src={el?.username ? `https://unavatar.io/telegram/${el?.username}` : 'https://unavatar.io/telegram/empty'}
                                        />
                                        <div
                                            className={"position-absolute top-medal"}
                                            style={{
                                                bottom: -13,
                                            }}
                                        >
                                            {el?.rank &&
                                                <OptimizedLottie
                                                    src={ (el.rank === 1 ? first : el.rank === 2 ? second : third) }
                                                    loop
                                                    autoplay
                                                    style={{
                                                        height: el?.rank !== 1 ? 30 : 30,
                                                        width: el?.rank !== 1 ? 30 : 30,
                                                    }}
                                                />}
                                        </div>
                                    </div>

                                    <Subheadline
                                        style={{
                                            color: "inherit!important",
                                            width: 100,
                                            marginTop: el?.rank !== 1 ? '10px' : ''
                                        }}
                                        className={"text-truncate"}
                                        weight={1}
                                    >
                                        <span>{el?.username}</span>
                                    </Subheadline>
                                    <Caption
                                        className={"d-flex gap-1 align-items-center justify-content-center"}
                                    >
                                        <IconTon
                                            style={{
                                                height: 15
                                            }}
                                        />
                                        {formatNumber(el?.amount)}
                                    </Caption>
                                    </a>
                                </div>

                        </div>
                    </Skeleton>
                ))}
            </div>
            <Button
                onClick={() => {
                    navigate('/top100')
                }}
                before={
                    <OptimizedLottie
                        src={topLottie}
                        loop
                        autoplay
                        style={{
                            height: 30,
                            width: 30
                        }}
                    />
                }
                mode={"gray"}
            >
                 Top 100 Winners
            </Button>
        </div>
    )
}
TopWinners.whyDidYouRender = true;
export default TopWinners