import {createSlice} from "@reduxjs/toolkit";

export const initialState = {
    topUsers: null,
    currentUser: null
}

const appSlice = createSlice({
    name: "users",
    initialState,
    reducers: {
        setTopUsers(state, action) {
            state.topUsers = action.payload
        },
        setCurrentUser(state, action) {
            state.currentUser = action.payload
        },
    }
})

export const {
    setTopUsers,
    setCurrentUser
} = appSlice.actions

export default appSlice.reducer