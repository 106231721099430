import {Card, Subheadline} from "@telegram-apps/telegram-ui";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {getGames} from "../../slices/games/thunk";
import Game from "../../Components/custom/Game/Game";
import TaskSkeleton from "./TaskSkeleton";

const Games = () => {
    const dispatch = useDispatch()
    const {games, loaded} = useSelector(state => state.Games)
    const {counters} = useSelector(state => state.App)

    useEffect(() => {
        if (!loaded)
            dispatch(getGames())
    }, [dispatch, loaded])

    return (
        <div className={"py-2"}>
            <Subheadline className={"text-grey ms-3"}>Games</Subheadline>
            <Card className={"d-flex flex-column mt-2 p-3 gap-3"}>
                {!loaded && Array.from({ length: counters?.count_games ?? 1}, (_, index) => (
                    <TaskSkeleton key={index} />
                ))}
                {games && games.map((el, i) => (
                    <Game key={i} current={el} />
                ))}
            </Card>
        </div>

    )
}

export default Games