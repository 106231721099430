import {getBalanceApi} from "../../helpers/real_api_helper";
import {setBalance} from "./reducer";

export const getBalance = () => async (dispatch) => {
    try {
        const res = await getBalanceApi();
        dispatch(setBalance(res.data));
    } catch (error) {
        console.error(error);
    }
}