import React from 'react';

// Мемоизируем компонент Avatar, чтобы избежать лишних рендеров
const MemoizedAvatar = React.memo(({ src, size }) => {
    return (
        <img
            alt={"avatar"}
            style={{
                width: size,
                height: size,
                borderRadius: '50px'
            }}
            src={src}
        />
    );
});

export default MemoizedAvatar;