import {useSelector} from "react-redux";
import {DotLottieReact} from "@lottiefiles/dotlottie-react";
import bell from "../assets/lottie/dotlottie/bell.lottie";
import {inviteFriends, openLink, openTelegramLink, subscribeTelegram} from "../helpers/telegram_app_helper";
import {Button, Card} from "@telegram-apps/telegram-ui";
import link from "../assets/lottie/dotlottie/link.lottie";
import React from "react";

export const useTaskRow = (task, i) => {
    const {user} = useSelector(state => state.Auth)

    const getEmoji = (type) => {
        switch (type) {
            case 'telegram_subscription':
                return bell
            case 'invite_referral':
                return link
            default:
                return bell
        }
    }

    const getTaskLink = (task) => {
        switch(task.type) {
            case 'telegram_subscription':
                let path = task.details.link
                return <a onClick={() => openTelegramLink(`${path}`)}>{path}</a>
            case 'youtube_subscription':
            case 'instagram_subscription':
            case 'x_subscription':
            case 'tiktok_subscription':
            case 'facebook_subscription':
                let link = task.details.link
                return <a onClick={() => openLink(link)}>{link}</a>
            case 'invite_referral':
                let count = task.details.count
                return (
                    <span>Invite {count} {count > 1 ? 'friends' : 'friend'}</span>
                )
            default:
                return ''
        }
    }

    const getTaskText = (task) => {
        switch(task.type) {
            case 'telegram_subscription':
            case 'youtube_subscription':
            case 'instagram_subscription':
            case 'x_subscription':
            case 'tiktok_subscription':
            case 'facebook_subscription':
                return 'Subscribe to'
            default:
                return ''
        }
    }

    const getTaskButton = (task) => {
        switch(task.type) {
            case 'telegram_subscription':
                let path = task.details.link
                return (
                    <Button
                        onClick={() => subscribeTelegram(path)}
                        stretched
                    >
                        Subscribe
                    </Button>
                )
            case 'youtube_subscription':
            case 'instagram_subscription':
            case 'x_subscription':
            case 'tiktok_subscription':
            case 'facebook_subscription':
                let youtube = task.details.link
                return (
                    <Button
                        onClick={() => openLink(youtube)}
                        stretched
                    >
                        Subscribe
                    </Button>
                )
            case 'invite_referral':
                return (
                    <Button
                        onClick={() => inviteFriends(user)}
                        stretched
                    >
                        Invite
                    </Button>
                )
        }
    }

    const getTaskRow = (task, i) => {
        return (
            <div key={i} className={"requirement flex-column gap-3 d-flex"}>
                <div className={"name d-flex gap-2 flex-wrap align-items-center w-100"}>
                    <DotLottieReact
                        src={getEmoji(task.type)}
                        loop
                        autoplay
                        style={{
                            flex: "0 0 auto",
                            height: 20,
                            width: 20,
                        }}
                    />
                    <span className="d-inline-block text-nowrap">
                        {getTaskText(task)}
                    </span>
                    <div className="link-container">
                        {getTaskLink(task)}
                    </div>
                </div>

                <div className={"w-100"}>
                    {getTaskButton(task)}
                </div>
            </div>
        )
    }

    return {getTaskRow}
}