import Footer from "./Footer";
import React, {useEffect} from 'react'
import 'react-toastify/dist/ReactToastify.css';
import {Flip, ToastContainer} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import ModalWelcome from "../Components/custom/ModalWelcome";
import ModalWelcomeTest from "../Components/custom/ModalWelcomeTest";
import RewardNumber from "../Components/custom/RewardNumber";
import {useLocation} from "react-router-dom";
import {setCurrentTabbarItem} from "../slices/app/reducer";
import {getCounters} from "../slices/app/thunk";
import {getBalance} from "../slices/balance/thunk";
import ModalFinal from "../Components/custom/ModalFinal";
import SocketEventListener from "../Components/custom/SocketEventListener";
import {SocketProvider} from "../context/SocketProvider";
import ModalPersonalWinner from "../Components/custom/Raffle/Modals/ModalPersonalWinner";
import {getNotifications} from "../slices/users/thunk";
import RaffleModals from "../Components/custom/Raffle/Modals/RaffleModals";
import WelcomeStories from "../Components/custom/WelcomeStories";
import Fireworks from "react-canvas-confetti/dist/presets/fireworks";
const Layout = (props) => {
    const {showConfetti, currentTab} = useSelector(state => state.App)
    const {user} = useSelector(state => state.Auth)

    const dispatch = useDispatch()
    const location = useLocation()
    const tabs = [
        {id: 'rewards', text: 'Rewards', path: "/rewards"},
        {id: 'lottys', text: 'Lotty', path: "/lotty"},
        {id: 'friends', text: 'Friends', path: "/friends"},
        {id: 'wallet', text: 'Wallet', path: "/wallet"}
    ]

    useEffect(() => {
        dispatch(getCounters())
        dispatch(getNotifications())
    }, [])

    useEffect(() => {
        let pathname = location.pathname
        let currentTabLocal = tabs.find(el => el.path === pathname)
        if (currentTabLocal && currentTab !== currentTabLocal.id) {
            dispatch(setCurrentTabbarItem(currentTabLocal?.id))
        }
    }, [tabs, currentTab])


    return (

            <React.Fragment>
                {/*<WelcomeStories />*/}
                {showConfetti && <div className={"confetti"}>
                    <Fireworks  autorun={{ speed: 1, duration: 0.5 }}  />
                </div>}

                <RaffleModals />
                <ModalPersonalWinner />
                <ToastContainer
                    position={"bottom-center"}
                    theme={'dark'}
                    autoClose={2000}
                    className={"p-3"}
                    hideProgressBar={true}
                />
                <ModalFinal />
                <div className={"content"}>
                    <RewardNumber />
                    <div className={"content-scroll tg-secondary-bg"}>
                        <div className={"tg-secondary-bg"}>
                            <div className={"min-page-content-height"}>
                                {props.children}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </React.Fragment>

    )
}

export default Layout