import {Image, Subheadline} from "@telegram-apps/telegram-ui";
import React from "react";
import useTaskIcon from "../../hooks/useTaskIcon";
import {getPrize} from "../../helpers/raffle_helper";

const Task = (props) => {
    const {task, onClick} = props

    const {src} = useTaskIcon(task)

    return (
        <div

            onClick={onClick}
            className={`task d-flex flex-row justify-content-between align-items-center ${task.is_available ? '' : 'disabled-task'}`}
            role={task.is_available ? 'button' : ''}
        >
            <div className={"d-flex flex-row gap-3 align-items-center w-75"}>
                <div className={"task-icon"}>
                    <Image size={65} src={src} />
                </div>

                <div className={"d-flex flex-column"}>
                    <Subheadline weight={2}>
                        {task.name}
                    </Subheadline>
                </div>
            </div>
            {getPrize(task?.reward_type, task?.reward, true)}
        </div>
    )
}

export default Task