import {useEffect, useState} from "react";
import Particles, {initParticlesEngine} from "@tsparticles/react";
import { loadAll } from "@tsparticles/all";


const FireworksParticles = () => {
    const particlesLoaded = (container) => {
        console.log(container);
    };

    const [init, setInit] = useState(false);

    // this should be run only once per application lifetime
    useEffect(() => {
        initParticlesEngine(async (engine) => {
            // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
            // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
            // starting from v2 you can add only the features you need reducing the bundle size
            //await loadAll(engine);
            //await loadFull(engine);
            await loadAll(engine);
            //await loadBasic(engine);
        }).then(() => {
            setInit(true);
        });
    }, []);

    const options = {
        emitters: {
            direction: "top",
            life: {
                count: 0,
                duration: 0.5,
                delay: 0.1
            },
            rate: {
                delay: 0.5,
                quantity: 2
            },
            size: {
                width: 100,
                height: 0
            },
            position: {
                y: 100,
                x: 50
            }
        },
        particles: {
            color: {
                value: "#fff"
            },
            number: {
                value: 0
            },
            destroy: {
                bounds: {
                    top: 10
                },
                mode: "split",
                split: {
                    count: 0,
                    factor: {
                        value: 0.333333
                    },
                    rate: {
                        value: 100
                    },
                    particles: {
                        stroke: {
                            width: 0
                        },
                        color: {
                            value: ["#ff595e", "#ffca3a", "#8ac926", "#1982c4", "#6a4c93"]
                        },
                        number: {
                            value: 0
                        },
                        collisions: {
                            enable: false
                        },
                        destroy: {
                            bounds: {
                                top: 0
                            }
                        },
                        opacity: {
                            value: {
                                min: 0.1,
                                max: 1
                            },
                            animation: {
                                enable: true,
                                speed: 0.7,
                                sync: false,
                                startValue: "max",
                                destroy: "min"
                            }
                        },
                        effect: {
                            type: "trail",
                            options: {
                                trail: {
                                    length: {
                                        min: 5,
                                        max: 10
                                    }
                                }
                            }
                        },
                        shape: {
                            type: "circle"
                        },
                        size: {
                            value: 2,
                            animation: {
                                enable: false
                            }
                        },
                        life: {
                            count: 1,
                            duration: {
                                value: {
                                    min: 1,
                                    max: 2
                                }
                            }
                        },
                        move: {
                            enable: true,
                            gravity: {
                                enable: true,
                                acceleration: 9.81,
                                inverse: false
                            },
                            decay: 0.1,
                            speed: {
                                min: 10,
                                max: 25
                            },
                            direction: "outside",
                            outModes: "destroy"
                        }
                    }
                }
            },
            life: {
                count: 1
            },
            effect: {
                type: "trail",
                options: {
                    trail: {
                        length: {
                            min: 10,
                            max: 30
                        },
                        minWidth: 1,
                        maxWidth: 1
                    }
                }
            },
            rotate: {
                path: true
            },
            shape: {
                type: "circle"
            },
            size: {
                value: 1
            },
            move: {
                enable: true,
                gravity: {
                    acceleration: 100,
                    enable: true,
                    inverse: true,
                    maxSpeed: 20
                },
                speed: {
                    min: 30,
                    max: 40
                },
                outModes: {
                    default: "destroy",
                    top: "none"
                }
            }
        },
    }

    if (init) {
        return (
            <Particles
                id="tsparticles"
                particlesLoaded={particlesLoaded}
                options={options}
            />
        );
    }

    return <></>;

}

export default FireworksParticles