import {createSlice} from "@reduxjs/toolkit";

export const initialState = {
    user: null
}

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        loginSuccess(state, action) {
            state.user = action.payload
        }
    }
})

export const {
    loginSuccess
} = authSlice.actions

export default authSlice.reducer