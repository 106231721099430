import React, {memo} from "react";
import {DotLottieReact} from "@lottiefiles/dotlottie-react";

const MemoDotLottie = memo((props) => {
    return (
        <DotLottieReact
            {...props}
        />
    )
})

export default MemoDotLottie