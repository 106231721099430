import Drawer from "../Drawer";
import {useTonAddress, useTonConnectUI} from "@tonconnect/ui-react";
import {Button, Card, Subheadline, Title} from "@telegram-apps/telegram-ui";
import {DotLottieReact} from "@lottiefiles/dotlottie-react";
import welcome from "../../../assets/lottie/dotlottie/finished.lottie";
import React from "react";
import OptimizedLottie from "../OptimizedLottie";

const ModalTonWallet = (props) => {
    const userFriendlyAddress = useTonAddress();
    const [tonConnectUI] = useTonConnectUI();

    const disconnectWallet = () => {
        tonConnectUI.disconnect();
        modalClose();
    };

    const {isOpen, modalClose} = props
    return (
        <Drawer
            isOpen={isOpen}
            onClose={modalClose}
        >
            <div className={"p-3 w-100 d-flex flex-column gap-3"}>
                <div className={"d-flex align-items-center justify-content-center flex-column gap-3"}>
                    <div className={"welcome-icon"}>
                        <OptimizedLottie
                            style={{
                                width: 150,
                                height: 75
                            }}
                            src={welcome}
                            loop
                            autoplay
                        />
                    </div>
                    <div className={"d-flex text-center"}>
                        <Title>
                            Ton wallet connected
                        </Title>
                    </div>
                </div>
                <div>
                    <div className={"d-flex p-3 w-100 b-8 main-bg"}>
                        <span className={"text-truncate"}>{userFriendlyAddress}</span>
                    </div>
                </div>

                <div className={"w-100 d-flex"}>
                    <Button
                        stretched
                        onClick={disconnectWallet}
                    >
                        Disconnect
                    </Button>
                </div>

            </div>
        </Drawer>
    )
}

export default ModalTonWallet