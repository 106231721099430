import {IoWalletOutline} from "react-icons/io5";
import {Button} from "@telegram-apps/telegram-ui";
import React, {useState} from "react";
import {useTonAddress, useTonConnectModal} from "@tonconnect/ui-react";
import ModalTonWallet from "./ModalTonWallet";

const ConnectWalletButton = () => {
    const { state, open, close } = useTonConnectModal();
    const [openWalletModal, setOpenWalletModal] = useState()
    const userFriendlyAddress = useTonAddress();
    const connectWallet = () => {
        if (userFriendlyAddress){
            setOpenWalletModal(true)
        }
        else
            open()
    }

    return (
        <>
            <ModalTonWallet
                isOpen={openWalletModal}
                modalClose={() => {
                    setOpenWalletModal(false)
                }}
            />
            <Button
                mode={"gray"}
                className={`d-block b-8 ${userFriendlyAddress ? 'ton-connected' : ''}`}
                before={
                    <div className={"py-1"}>
                        <IoWalletOutline size={20} />
                    </div>
                }
                onClick={connectWallet}
                size={"s"}
            >
                {userFriendlyAddress ? 'Connected' : 'Connect Ton'}
            </Button>
        </>

    )
}

export default ConnectWalletButton