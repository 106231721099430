import ModalRaffleWinner from "./ModalRaffleWinner";
import ModalRaffleFinished from "./ModalRaffleFinished";
import ModalRaffleParticipated from "./ModalRaffleParticipated";
import ModalRaffle from "./ModalRaffle";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {setSelectedRaffle} from "../../../../slices/raffles/reducer";

const RaffleModals = () => {
    const {selectedRaffle} = useSelector(state => state.Raffles)
    const dispatch = useDispatch()

    const onModalClose = () => {
        dispatch(setSelectedRaffle(false))
    }

    const getModalComponent = () => {
        if (selectedRaffle?.is_closed && selectedRaffle?.is_participating) {
            return (
                <ModalRaffleWinner
                    selectedRaffle={selectedRaffle}
                    modalClose={onModalClose}
                    isOpen={selectedRaffle}
                />
            );
        }
        else if (selectedRaffle?.is_closed && !selectedRaffle?.is_participating) {
            return (
                <ModalRaffleFinished
                    selectedRaffle={selectedRaffle}
                    modalClose={onModalClose}
                    isOpen={selectedRaffle}
                />
            );
        }

        else if (!selectedRaffle?.is_closed && selectedRaffle?.is_participating) {
            return (
                <ModalRaffleParticipated
                    selectedRaffle={selectedRaffle}
                    modalClose={onModalClose}
                    isOpen={selectedRaffle}
                />
            );
        }

        return (
            <ModalRaffle
                selectedRaffle={selectedRaffle}
                modalClose={onModalClose}
                isOpen={selectedRaffle}
            />
        );
    };

    return (
        selectedRaffle && getModalComponent()
    )
}
export default RaffleModals