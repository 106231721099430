import {createSlice} from "@reduxjs/toolkit";

export const initialState = {
    tasks: [],
    showSkeleton: false,
    loaded: false,
    dailyLoaded: false,
    shouldUpdate: false,
    dailyTasks: []
}

const appSlice = createSlice({
    name: "tasks",
    initialState,
    reducers: {
       setTasks(state, action) {
           state.tasks = action.payload
           state.loaded = true;
       },
        setDailyTasks(state, action) {
            state.dailyTasks = action.payload
            state.dailyLoaded = true;
        },
        setShowSkeleton(state, action) {
            state.showSkeleton = action.payload
        }
    }
})

export const {
    setTasks,
    setShowSkeleton,
    setDailyTasks
} = appSlice.actions

export default appSlice.reducer